/* auth animation */
.hero-animation {
  overflow: hidden;
  position: relative;
  max-height: 478px;
  min-height: 478px;
}
.hero-animation .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2.5px);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0.26%,
    #ffffff 77.91%
  );
}
.hero-animation .slider-bg-img img {
  width: 100%;
  height: 100%;
}
.hero-animation .marquee-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
.hero-animation .marquee-block {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: rotate(-16deg);
  -webkit-transform: rotate(-16deg);
  -moz-transform: rotate(-16deg);
  -ms-transform: rotate(-16deg);
  -o-transform: rotate(-16deg);
}
.hero-animation .marquee-inner {
  position: absolute;
}

.hero-animation .marquee-inner.to-left {
  animation: marqueeTop 25s linear infinite;
}
.hero-animation .marquee-inner.to-right {
  animation: marqueeBottom 25s linear infinite;
}
.hero-animation .marquee-item {
  width: 100%;
  transition: all 0.2s ease-out;
  margin: 20px 0px;
}
@keyframes marqueeTop {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}
@keyframes marqueeBottom {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}

/* auth animation */

.auth-form {
  max-width: 574px;
  width: 100%;
  padding: 56px 48px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #edf1f8;
  box-shadow: 0px 50px 60px -24px rgba(36, 55, 87, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  -webkit-transform: translate(-50%, -40%);
  -moz-transform: translate(-50%, -40%);
  -ms-transform: translate(-50%, -40%);
  -o-transform: translate(-50%, -40%);
}
.auth-form h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: #0f1724;
  padding-bottom: 59px;
}
.auth-form h2 span {
  color: #346dfe;
}
.auth-form p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.0035em;
  color: #3c5b90;
}
.auth-form .form-control {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #919eab;
  padding: 16px 14px;
}
.auth-form .eye {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  cursor: pointer;
}
.auth-form .forgot-password {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #012c98;
}

.auth-form .orSignin {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #919eab;
}
.auth-form .social-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 0;
}
.auth-form .social-btn .btn {
  max-width: 36px;
  max-height: 36px;
  padding: 0;
}
.auth-form .social-btn .btn img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  image-rendering: optimizeQuality;
}
.auth-form .createAccount {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.0035em;
  color: #031430;
}
.auth-form .createAccount span {
  color: #013fd7;
}
.auth-form .form-check .form-check-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #71717a;
}
.auth-form .form-check .form-check-label a {
  color: #013fd7;
  text-decoration: underline !important;
}
.auth-form .returnToSignIn {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000f33;
}

/* form modal */
.request-demo-modal .modal-content {
  background: rgb(230, 237, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(230, 237, 255, 1) 30%,
    rgba(255, 255, 255, 1) 30%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(230, 237, 255, 1) 30%,
    rgba(255, 255, 255, 1) 30%
  );
  background: linear-gradient(
    180deg,
    rgba(230, 237, 255, 1) 30%,
    rgba(255, 255, 255, 1) 30%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e6edff",endColorstr="#ffffff",GradientType=1);
}
.request-demo-modal .modal-header {
  border: 0px;
}
.request-demo-modal .modal-body .closeBtn {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}

.request-demo-modal .modal-body .auth-form {
  position: unset;
  max-width: 580px;
  width: 100%;
  box-shadow: none;
  border: 0px;
  padding: 28px 24px;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  border-radius: 8px;
  background: var(--background-light-paper, #fff);
  box-shadow: 0px 2px 3px 0px rgba(0, 29, 102, 0.08),
    0px 6px 18px 4px rgba(183, 198, 225, 0.29);
  margin-bottom: 38px;
}
.request-demo-modal .modal-body .auth-form h2 {
  color: #121212;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 120% */
  letter-spacing: -0.105px;
  padding-bottom: 8px;
}
.request-demo-modal .modal-body .auth-form p {
  color: #121212;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.049px;
  margin-bottom: 48px;
}
.request-demo-modal .modal-body .auth-form .form-group {
  position: relative;
}
.request-demo-modal .modal-body .auth-form .form-group .form-label {
  background-color: #fff;
  color: #919eab;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  padding: 6px 5px;
  cursor: text;
}
.request-demo-modal .modal-body .auth-form .form-group .form-label.deactive {
  position: absolute;
  top: 50%;
  left: 16px;
  padding: 0px 0px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transition: all linear 0.1s;
  -webkit-transition: all linear 0.1s;
  -moz-transition: all linear 0.1s;
  -ms-transition: all linear 0.1s;
  -o-transition: all linear 0.1s;
}
.request-demo-modal
  .modal-body
  .auth-form
  .form-group.phoneNumber
  .form-label.deactive {
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transition: all linear 0.1s;
  -webkit-transition: all linear 0.1s;
  -moz-transition: all linear 0.1s;
  -ms-transition: all linear 0.1s;
  -o-transition: all linear 0.1s;
  z-index: -1;
}
.request-demo-modal .modal-body .auth-form .form-group .form-label.active {
  position: absolute;
  top: 0%;
  left: 14px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transition: all linear 0.1s;
  -webkit-transition: all linear 0.1s;
  -moz-transition: all linear 0.1s;
  -ms-transition: all linear 0.1s;
  -o-transition: all linear 0.1s;
}
.request-demo-modal .modal-body .auth-form .form-control {
  padding: 16px 14px;
  color: #919eab;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  z-index: 9;
}
.request-demo-modal .modal-body .auth-form .form-control.PhoneInput {
  padding: 0px;
  color: #919eab;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  background: transparent;
}
.request-demo-modal
  .modal-body
  .auth-form
  .form-control.PhoneInput
  .PhoneInputCountry {
  padding: 0px 0px 0px 14px;
}
.request-demo-modal
  .modal-body
  .auth-form
  .form-control.PhoneInput
  .PhoneInputInput {
  padding: 16px 14px;
  background-color: transparent;
}

.request-demo-modal .modal-body .auth-form .form-select {
  cursor: pointer;
}
.request-demo-modal .modal-body .auth-form .btn,
.request-demo-modal .modal-body .auth-form .btn:hover {
  /* margin-top: 60px; */
  border-radius: 8px;
  background: #117fea;
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 173.333% */
  padding-top: 11px;
  padding-bottom: 11px;
}
@media (min-width: 1200px) and (max-width: 1470px) {
  .hero-animation {
    max-height: 400px;
    min-height: 400px;
  }
}
@media (min-width: 1126px) and (max-width: 1199px) {
  .hero-animation {
    max-height: 380px;
    min-height: 380px;
  }
}
@media (min-width: 1024px) and (max-width: 1126px) {
  .hero-animation {
    max-height: 350px;
    min-height: 350px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .hero-animation {
    max-height: 200px;
    min-height: 200px;
  }
}
@media screen and (max-width: 991px) {
  .hero-animation {
    max-height: 180px;
    min-height: 180px;
  }
  .hero-animation .marquee-wrap {
    gap: 15px;
  }
  .hero-animation .marquee-item {
    margin: 16px 0px;
  }
  .auth-form {
    /* max-width: 288px; */
    width: calc(100% - 0%);
    padding: 32px 24px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
  .auth-form h2 {
    font-size: 20px;
    line-height: 27px;
  }
}

@media screen and (max-width: 768px) {
  .request-demo-modal {
    padding-left: 0 !important;
  }
  .request-demo-modal .modal-body .closeBtn {
    top: -10px;
    right: -8px;
  }
  .request-demo-modal .modal-body .closeBtn svg {
    width: 40px;
    height: 40px;
  }
  .request-demo-modal .modal-body .auth-form {
    padding: 10px 16px;
  }
  .request-demo-modal .modal-body .auth-form h2 {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .request-demo-modal .modal-body .auth-form p {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .request-demo-modal .modal-body .auth-form .form-group .form-label.deactive {
    margin-bottom: 0;
  }
  .request-demo-modal .modal-body .auth-form .form-control,
  .request-demo-modal
    .modal-body
    .auth-form
    .form-control.PhoneInput
    .PhoneInputInput,
  .request-demo-modal .modal-body .auth-form .btn,
  .request-demo-modal .modal-body .auth-form .btn:hover {
    padding: 8px 10px;
  }
  .request-demo-modal .modal-body .auth-form .btn {
    font-size: 12px;
  }
}
