/* .about-hero */
.about-hero {
  padding: 166px 0;
  background: url("./../images/about/about-hero-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.about-hero .fullwidth-heading {
  max-width: 646px;
  margin: 0 auto;
}

.about-hero .fullwidth-heading .gradient-text {
  font-weight: 800;
  font-size: 150px;
  line-height: 180px;
  display: block;
}

.about-hero .fullwidth-heading h2 {
  font-weight: 800;
  font-size: 150px;
  line-height: 180px;
}

.about-hero .btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;
}

/* .about-hero */

/* about-us */
.about-us {
  padding: 97px 0;
}

.about-us h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #013fd7;
}

.about-us p {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #393e50;
}

/* about-us */

/* about-driven */
.about-driven {
  padding: 84px 0;
}

.about-driven .heading {
  padding-bottom: 64px;
}

.about-driven .text-content a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #243757;
}
.about-driven .info-card .card-logo {
  background: transparent;
  padding: 0;
  max-width: 80px;
}
/* about-driven */

/* about-payrollService */
.about-payrollService {
  padding: 150px 0 240px;
  background: url("./../images/about/payroll.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.about-payrollService .fullwidth-heading {
  /* max-width: 684px; */
  max-width: 570px;
}

.about-payrollService .fullwidth-heading .section-title {
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 20px;
  color: #013fd7 !important;
}

.about-payrollService .fullwidth-heading .section-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.5px;
  color: #013fd7;
  margin-bottom: 8px;
}

.about-payrollService .fullwidth-heading h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: #243757 !important;
  max-width: 508px;
  text-align: left;
  margin-right: auto;
  margin-left: inherit;
}

.about-payrollService .fullwidth-heading p {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 22px;
  color: #243757 !important;
  text-align: left;
}

.about-payrollService .fullwidth-heading .btn-group {
  max-width: 424px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.about-payrollService .fullwidth-heading .btn-group .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.about-payrollService .fullwidth-heading .btn-group .btn:last-child {
  font-weight: 600;
  font-size: 14px;
  /* line-height: 18px; */
  color: #243757;
  border: 1px solid #243757;
}

/* about-payrollService */

/* about-experience */
.about-experience .tab-section .nav-tabs li {
  /* flex-basis: 31.8%; */
  flex: 1 0 auto;
}

.about-experience .tab-section .nav-tabs li .nav-link {
  width: 100%;
  color: #000000 !important;
}

.about-experience .tab-section .nav-tabs li .nav-link.active {
  color: #fff !important;
}

.about-experience .tab-section .tab-content .tab-pane .heading p {
  margin-bottom: 72px;
}

/* about-experience */

/* responsive */
@media screen and (max-width: 992px) {
  .about-hero {
    padding: 40px 0;
  }

  .about-hero .fullwidth-heading h2 {
    font-size: 48px;
    line-height: 58px;
  }

  .about-hero .fullwidth-heading h2 span {
    font-size: 48px !important;
    line-height: 58px !important;
  }

  .about-hero .fullwidth-heading .btn-group {
    margin-top: 0 !important;
  }

  .about-hero .fullwidth-heading > div .btn-group {
    flex-direction: column;
    gap: 8px;
    margin-top: 45px !important;
  }

  .about-hero .fullwidth-heading > div .btn-group .btn {
    width: 100%;
  }

  .about-us {
    padding: 40px 0;
  }

  .about-driven {
    padding: 40px 0;
  }
  .info-card .text-content p {
    max-width: 100%;
  }

  .about-payrollService {
    background: none;
    padding: 40px 0;
  }

  .about-payrollService .fullwidth-heading .section-title {
    text-align: center;
    font-size: 12px;
  }

  .about-payrollService .fullwidth-heading .title {
    text-align: center;
    font-size: 24px;
    line-height: 29px;
  }

  .about-payrollService .fullwidth-heading .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .about-payrollService .fullwidth-heading .btn-group {
    margin-top: 0 !important;
  }

  .about-payrollService .fullwidth-heading > div .btn-group {
    flex-direction: column;
    margin-bottom: 44px;
  }

  .about-payrollService .fullwidth-heading > div .btn-group .btn {
    width: 100%;
  }

  .payroll-works .tab-section {
    margin-top: 40px !important;
  }

  .payroll-works .tab-section .nav-tabs {
    margin-bottom: 20px !important;
    gap: 8px !important;
  }

  .payroll-works .tab-section .nav-tabs .nav-link {
    padding: 7px 28px !important;
  }

  .payroll-works .tab-section .tab-content .heading {
    text-align: center !important;
  }

  .payroll-works .tab-section .tab-content .heading p {
    margin-bottom: 40px !important;
  }

  .payroll-works .tab-section .tab-content .heading .btn {
    width: 100%;
    margin-bottom: 40px !important;
  }
}
