.footer {
  background: #243757;
  border-top: 2px solid rgba(36, 55, 87, 0.12);
  padding-top: 40px;
}
.footer .subscribe-form input {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.32);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  /*opacity: 0.4;
  */
  border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -ms-border-radius: 8px 0 0 8px;
  -o-border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
}
.footer .subscribe-form .btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #013fd7;
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
  -webkit-border-radius: 0px 8px 8px 0px;
  -moz-border-radius: 0px 8px 8px 0px;
  -ms-border-radius: 0px 8px 8px 0px;
  -o-border-radius: 0px 8px 8px 0px;
}

.footer .subscribe-lebel {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-top: 8px;
}
.footer .footer-heading {
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #edf1f8;
  margin-top: 40px;
}
.footer ul li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #dbe3f0;
  margin-bottom: 12px;
}
.footer ul li:last-child a {
  margin-bottom: 0;
}
.footer .tiny_footer p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.footer .tiny_footer .social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.footer .tiny_footer .social li {
}
.footer .tiny_footer .social li a {
  margin-bottom: 0 !important;
  display: inline-block;
}
.footer {
}
.footer {
}
.footer {
}
.footer {
}
.footer {
}
.footer {
}
.footer {
}
.footer {
}
.footer {
}
.footer {
}
.footer {
}
