.customer-hero .text-content {
  /* max-width: 800px; */
  max-width: 60%;
  width: 100%;
  margin: auto;
}

.customer-hero p {
  color: rgba(88, 92, 123, 1);
}
/* customer-banner */

/* .success-section .content-wrapper .stories-img {
  position: relative;
}
.success-section .content-wrapper .stories-img .company-card-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: end;
  border: 0;
}
.success-section .content-wrapper .stories-img .company-card-2 .img-content {
  display: none;
} */

/* custom grid layout */
.success-section .custom-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  height: auto; /* Updated to 'auto' */
}
.success-section .custom-grid div:nth-child(1) {
  grid-column: span 3;
}
.success-section .custom-grid div:nth-child(2) {
  position: relative;
  grid-column: span 6;
  grid-row: span 2;
}
.success-section .custom-grid div:nth-child(3) {
  grid-column: span 3;
}
.success-section .custom-grid div:nth-child(4) {
  grid-column: span 3;
}
.success-section .custom-grid div:nth-child(5) {
  grid-column: span 3;
}
.success-section .custom-grid > div {
  width: 100%;
  height: 100%;
}
.success-section .custom-grid div:nth-child(2) .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

/* custom grid layout */

/* Company card component  */
.company-card,
.company-card-2 {
  padding: 20px;
  /* margin-bottom: 20px; */
}

.company-card h3 {
  color: #332a5d;
  z-index: 1;
}

.company-card p {
  color: #6c6a72;
  z-index: 1;
}

.company-card-2 {
  background-color: rgba(0, 19, 64, 1);
  /* min-height: 306px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.company-card-2 p {
  color: #ffffff;
}

.company-card-2 h3 {
  color: rgba(194, 200, 209, 1);
}

.company-card-2 a {
  color: #ffffff !important;
}

.company-card-2 img {
  height: 70px;
  width: 70px;
  object-fit: cover;
}

.success-section {
  background: rgba(36, 55, 87, 1);
}

.success-section p {
  color: #c2c8d1;
}
.img-content,
.text-content {
  z-index: 1;
}
.payroll-feature .img-content img {
  max-height: 400px;
  height: 100%;
  width: 100%;
  object-fit: scale-down;
  overflow: hidden;
}
/* REsponsive code started from here started  */
@media screen and (max-width: 993px) {
  .success-section .title {
    text-align: center !important;
  }

  .success-section .content-wrapper {
    flex-direction: column;
  }

  .success-section .company-card-2 {
    min-height: auto !important;
  }
  .success-section .custom-grid {
    grid-template-rows: repeat(1, 1fr);
  }
  .success-section .custom-grid div:nth-child(1) {
    grid-column: span 6;
    order: 1;
  }
  .success-section .custom-grid div:nth-child(2) {
    grid-column: span 12;
    order: 3;
  }
  .success-section .custom-grid div:nth-child(3) {
    grid-column: span 6;
    order: 2;
  }
  .success-section .custom-grid div:nth-child(4) {
    grid-column: span 6;
    order: 4;
  }
  .success-section .custom-grid div:nth-child(5) {
    grid-column: span 6;
    order: 5;
  }
}

@media screen and (max-width: 600px) {
  .success-section .custom-grid div:nth-child(1) {
    grid-column: span 12;
  }
  .success-section .custom-grid div:nth-child(2) {
    grid-column: span 12;
  }
  .success-section .custom-grid div:nth-child(3) {
    grid-column: span 12;
  }
  .success-section .custom-grid div:nth-child(4) {
    grid-column: span 12;
  }
  .success-section .custom-grid div:nth-child(5) {
    grid-column: span 12;
  }
  .success-section .custom-grid div:nth-child(3) .text-content {
    top: 92%;
    left: 8%;
  }
}
