body {
  overflow-x: hidden;
}

.badge-group {
  display: flex;
  gap: 15px;
}

.trending-blogs-section {
  margin-top: 144px;
  margin-bottom: 80px;
}

.trending-blogs-section .slider-pagination-btn {
  display: flex;
  justify-content: right;
}

.trending-blogs-section .slick-prev {
  background-image: url("../images/blog/icon/arrow.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  left: 100% !important;
  margin-top: 10px;
  margin-left: -100px;
}

.trending-blogs-section .slick-next {
  background-image: url("../images/blog/icon/arrow.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  transform: rotate(180deg) !important;
  margin-right: 50px !important;
}

.trending-blogs-section .slick-prev::before,
.trending-blogs-section .slick-next:before {
  opacity: 0 !important;
}

.trending-blogs-section .slick-arrow {
  top: 102%;
  z-index: 10;
}

.trending-blogs-section .slick-dots {
  display: flex !important;
  gap: 20px;
  margin-left: 15px;
}

.trending-blogs-section .slick-dots li {
  list-style: none !important;
  background-color: rgba(7, 16, 25, 0.1);
  height: 2px;
  width: 30px;
  height: 2px;
}

.trending-blogs-section .slick-dots li button {
  width: 30px;
  height: 2px;
}

.trending-blogs-section .slick-dots li.slick-active {
  background-color: rgba(1, 63, 215, 1);
}

.trending-blogs-section .slick-dots li button::before {
  display: none;
}

.trending-blogs-section .slick-slide {
  padding: 0px 20px;
}

/* .hero-with-search component  */
.hero-with-search p {
  max-width: 662px;
  margin: auto;
  text-align: center;
}

.hero-with-search form {
  max-width: 460px;
  margin: auto;
}

.hero-with-search form {
  position: relative;
}

.hero-with-search form input {
  min-height: 52px;
}

.hero-with-search form a {
  position: absolute;
  right: 5px;
  top: 10%;
}

/* Component ended   */

/* Breadcum componenmt  */
.breadcum span {
  color: #115175;
}

.breadcum span {
  margin-right: 40px;
  position: relative;
}

.breadcum span::after {
  content: "";
  background-image: url("../images/blog/icon/rightArrow.png");
  height: 20px;
  width: 20px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 1px;
  margin-left: 8px;
}

.breadcum span:last-child::after {
  display: none;
}

.post-info .user-img {
  height: 40px;
  width: 40px;
}

.post-info {
  margin-top: 40px;
}

.post-info p,
.post-info span {
  color: #9397ad;
  margin-bottom: 0px;
}

.post {
  margin-top: 80px;
}

.post p,
.post li {
  color: #585c7b;
}
.post article {
  background: url("./../images/blog/icon/“.png") no-repeat left;
  background-size: auto;
  padding: 43px 0 14px;
  margin-bottom: 40px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #243757;
}
.post address {
  font-family: "SF Pro Display";
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #131022;
}

.lists ul {
  padding-left: 2rem;
}
.lists li {
  list-style: disc;
}

.relatedTags span {
  border: 1px solid #e2e5f1;
  border-radius: 4px;
  padding: 5px 8px;
  text-decoration: underline;
}

.relatedTags a {
  border: 1px solid #e2e5f1;
  border-radius: 4px;
  padding: 5px 8px;
  color: #3e4265;
}

.relatedTags .active {
  background-color: #eff2fc;
}

.pagination h3,
.pagination h4 {
  color: #9397ad;
  /* text-decoration: underline; */
}

.pagination .next-btn {
  transform: rotate(180deg);
}

.related-segment {
  background-color: #f9f9f9;
  margin-bottom: 40px;
}

.related-segment li {
  background-color: white;
  border-radius: 4px;
}

.related-segment span {
  background: #ededed;
  border-radius: 10px;
  padding: 8px;
  position: absolute;
  left: 95%;
}

.related-segment span.active {
  background: #013fd7;
  color: white;
}

.blog-cat {
  margin-top: 16px;
}
/* trending, recent, popular post */
.related-segment div .img-content {
  flex: 0 0 60px;
}

/* trending, recent, popular post */
/* blog-form  */
.blog-form {
  background-color: #edf1f8;
}

.blog-form .form-group {
  margin: 32px 0px;
}

.blog-form label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;

  /* identical to box height, or 26px */

  color: #131022;
}

.blog-form input,
.blog-form textarea {
  padding: 10px;
  border-radius: 8px;
}

/* comment */
.show-comments,
.show-comments:hover,
.show-comments:focus,
.show-comments:focus-visible,
.show-comments:focus-within,
.show-comments:active {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  border: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #585c7b !important;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
}

.show-comments .down {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.show-comments .up {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.comment .img {
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.comment .title h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #243757;
}
.comment .title .date-time {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9397ad;
}

.comment .comment-body p {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #585c7b;
  padding: 10px 0 16px;
}
.comment .action-btn .replay p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #9397ad;
}
.comment .action-btn .like p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #9397ad;
  cursor: pointer;
}
.comment .action-btn .liked p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #115175;
  cursor: pointer;
}

.replay-comment {
  padding-left: 3.5rem;
  margin-top: 25px;
}

/* comment */

@media screen and (max-width: 768px) {
  .blog-page .details-section-6x6 .text-content a {
    width: 100% !important;
  }

  .trending-blogs-section .heading {
    width: 100%;
    flex-direction: column !important;
    align-items: center;
  }

  .trending-blogs-section .heading div {
    width: 100%;
    text-align: center;
  }

  .trending-blogs-section .heading div a {
    width: 90%;
    margin-top: 20px;
    text-align: center;
  }

  .trending-blogs-section {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .relatedTags {
    flex-wrap: wrap;
  }
  /* .relatedTags h3 {
    font-size: 13px;
  }
  .relatedTags a {
    font-size: 14px;
  } */
  .replay-comment {
    padding-left: 0rem;
    margin-top: 25px;
  }
  .comment .img {
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
  }
  .comment .img img {
    width: 100%;
    height: 100%;
  }

  .comment .action-btn,
  .comment .comment-body p,
  .comment .comment-img-title {
    padding-left: 10px;
  }
}
