/* api-doc-banner */
.api-doc-banner {
  padding-top: 46px;
  padding-bottom: 46px;
  position: relative;
}
.api-doc-banner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.api-doc-banner h2 {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 62px;
  letter-spacing: -2px;
}
.api-doc-banner form {
  max-width: 320px;
  position: relative;
  margin-bottom: 1rem;
}
.api-doc-banner form input {
  padding-left: 2.8rem;
  padding-top: 12px;
  padding-bottom: 12px;
}
.api-doc-banner form span {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.api-doc-banner p {
  color: #e6edff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  max-width: 458px;
}

/* api-doc-banner */

/* custom accordion */
.accordion-menu {
  font-size: 15px;
  background: #0277bd;
  color: white;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.accordion-content {
  height: 0;
  overflow: hidden;
  font-size: 14px;
}

.accordion-content.expanded {
  height: 0;
  overflow: hidden;
}

/* custom accordion */

/* ApiDocsContent */
.ApiDocsContent .position-sticky {
  top: 100px;
}
.ApiDocsContent .sidenav {
  padding: 16px 24px 32px 24px;
  border-radius: 0px 0px 8px 8px;
  border-right: 1px solid var(--text-color-200, #b7c6e1);
  border-bottom: 1px solid var(--text-color-200, #b7c6e1);
  border-left: 1px solid var(--text-color-200, #b7c6e1);
}
.ApiDocsContent .sidenav hr {
  color: #dbe3f0;
}
.ApiDocsContent .sidenav h2 {
  color: #0f1724;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.ApiDocsContent .sidenav a {
  color: #243757;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.2px;
}
.ApiDocsContent .sidenav p {
  color: #6f8ec3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 25.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 56px;
}

.ApiDocsContent .sidenav .btn {
  padding: 12px;
  border: 0;
  outline: none;
  position: sticky;
}
.ApiDocsContent .sidenav .accordion {
  padding: 0px 0px 0px 0px;
  margin-bottom: 1rem;
  margin-left: 15px;
  position: relative;
}
.ApiDocsContent .sidenav .accordion::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  width: 20px;
  height: calc(100% - 1.3rem);
  border-left: 1px solid #dbe3f0;
  border-bottom-left-radius: 10px;
}

.ApiDocsContent .sidenav .accordion .accordion-group .accordion-menu {
  color: #0f1724;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.2px;
  background-color: transparent;
  box-shadow: none;
  padding: 12px 12px 12px 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ApiDocsContent .sidenav .accordion .accordion-group .accordion-menu span {
  font-size: 20px;
  color: #013fd7;
}

.ApiDocsContent .sidenav .accordion .accordion-group .accordion-menu::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 13px;
  height: 1px;
  background-color: #dbe3f0;
}
.ApiDocsContent
  .sidenav
  .accordion
  .accordion-group:last-child
  .accordion-menu::before {
  height: 0;
}
.ApiDocsContent .sidenav .accordion .accordion-group .accordion-content li {
  color: #3c5b90;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 25.2px */
  padding: 12px 12px 12px 12px;
  margin-left: 13px;
  cursor: pointer;
}
.ApiDocsContent
  .sidenav
  .accordion
  .accordion-group
  .accordion-content
  li.active {
  background: #edf1f8;
}
.ApiDocsContent
  .sidenav
  .accordion
  .accordion-group
  .accordion-content
  li:hover {
  background: #edf1f8;
}
.ApiDocsContent .sidenav .collapse-div .accordion-group .accordion-content {
}
.ApiDocsContent .content {
  padding-left: 118px;
  padding-top: 56px;
  padding-bottom: 56px;
}
.ApiDocsContent .content h1 {
  color: #121212;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 1rem;
}
.ApiDocsContent .content h2 {
  color: #121212;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
}
.ApiDocsContent .content p {
  color: #2d446c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  margin-bottom: 24px;
}
.ApiDocsContent .content ul li {
  color: #2d446c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  list-style-type: disc;
  margin-left: 1rem;
}
.ApiDocsContent .content .dateTime {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.3;
}
.ApiDocsContent .content .dateTime p {
  margin-bottom: 0;
  color: #1e2d48;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.ApiDocsContent .content {
}
/* ApiDocsContent */

@media screen and (max-width: 768px) {
  .ApiDocsContent .content {
    padding-left: 0px;
  }
}
