/* organogram-management-hero start */
.organogram-management-hero {
  padding-top: 64px;
}

.organogram-management-hero .fullwidth-heading {
  max-width: 926px;
  margin: 0 auto 66px;
}

.organogram-management-hero .fullwidth-heading .section-title {
  margin-bottom: 8px;
}

.organogram-management-hero .fullwidth-heading .title {
  margin-bottom: 27px;
}
.organogram-management-hero .fullwidth-heading .desc {
  color: #475569;
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 64px;
}

.organogram-management-hero .fullwidth-heading .btn-group {
  max-width: 355px;
  margin: 0 auto;
}

/* organogram-management-hero end */

/* company-management-hero start  */
.company-management-hero {
  padding-top: 64px;
}

.company-management-hero .fullwidth-heading {
  max-width: 926px;
  margin: 0 auto 66px;
}

.company-management-hero .fullwidth-heading .section-title {
  margin-bottom: 8px;
}

.company-management-hero .fullwidth-heading .title {
  margin-bottom: 27px;
}
.company-management-hero .fullwidth-heading .desc {
  color: #475569;
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 64px;
}

.company-management-hero .fullwidth-heading .btn-group {
  max-width: 355px;
  margin: 0 auto;
}

/* company-management-hero end */

/* benefit-hero start  */
.benefit-hero {
  padding-top: 64px;
}

.benefit-hero .fullwidth-heading {
  max-width: 926px;
  margin: 0 auto 66px;
}

.benefit-hero .fullwidth-heading .section-title {
  margin-bottom: 8px;
  text-transform: capitalize !important;
}

.benefit-hero .fullwidth-heading .title {
  margin-bottom: 27px;
}
.benefit-hero .fullwidth-heading .desc {
  color: #475569;
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 64px;
}

.benefit-hero .fullwidth-heading .btn-group {
  max-width: 355px;
  margin: 0 auto;
}
/* benefit-hero end  */

/* Visualization And Navigation start  */
.visualization-and-navigation {
  padding: 64px 0;
}
.visualization-and-navigation .fullwidth-heading .section-title {
}
.visualization-and-navigation .fullwidth-heading .title {
  max-width: 855px;
  margin: auto;
  width: 100%;
  color: #243757;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: -24px;
}
.visualization-and-navigation .fullwidth-heading p {
  color: #475569;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 44px;
}

.visualization-and-navigation .info-card {
  min-height: 260px;
  border-radius: 13.215px;
  border: 1px solid rgba(91, 119, 147, 0.24);
  background: #f9fafb;
  padding: 24px;
}

.visualization-and-navigation .info-card .img-content img {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 17.169px 34.337px 0px rgba(145, 158, 171, 0.16);
  padding: 12px;
  margin-bottom: 16px;
}

.visualization-and-navigation .info-card .text-content h3 {
  color: #212b36;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.171px;
  margin-bottom: 7px;
}
.visualization-and-navigation .info-card .text-content p {
  color: #2d446c;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  opacity: 0.8;
}
/* Visualization And Navigation end  */

/* structure-understand-accessibility start  */
.structure-understand-accessibility {
}
.structure-understand-accessibility {
  padding: 80px 0;
}
.structure-understand-accessibility .fullwidth-heading .title {
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  margin-bottom: 16px;
}
.structure-understand-accessibility .fullwidth-heading .desc {
  color: #243757;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.structure-understand-accessibility .card {
  background: #f6f8fc;
  border-radius: 20px;
  padding: 24px !important;
  height: 100%;
  border: 1px solid #bbd1f0;
}
.structure-understand-accessibility .text-content {
  margin-bottom: 80px;
}
.structure-understand-accessibility .text-content h3 {
  color: #212b36;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 16px;
}
.structure-understand-accessibility .text-content p {
  color: #2d446c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  opacity: 0.8;
}
/* structure-understand-accessibility end  */

/* real-time-tracking-of-employee start  */
.real-time-tracking-of-employee .img-content img {
  border-radius: 7.12px;
}
.real-time-tracking-of-employee .top-direction .text-content{
  margin-bottom: 24px;

}
.real-time-tracking-of-employee .bottom-direction .text-content{
  margin-top: 24px;
  margin-bottom: 0;
}
/* real-time-tracking-of-employee end  */
@media screen and (max-width: 768px) {
  /* organogram-management-hero start  */
  .organogram-management-hero {
    padding-top: 40px;
  }
  .organogram-management-hero .fullwidth-heading .section-title {
    font-size: 14px;
  }
  .organogram-management-hero .fullwidth-heading .title {
    font-size: 32px;
    margin-bottom: 16px;
  }
  .organogram-management-hero .fullwidth-heading .desc {
    font-size: 14px;
    margin-bottom: 32px;
  }

  /* organogram-management-hero end  */

  /* company-management-hero start  */
  .company-management-hero {
    padding-top: 40px;
  }
  .company-management-hero .fullwidth-heading .section-title {
    font-size: 14px;
  }
  .company-management-hero .fullwidth-heading .title {
    font-size: 32px;
    margin-bottom: 16px;
  }
  .company-management-hero .fullwidth-heading .desc {
    font-size: 14px;
    margin-bottom: 32px;
  }
  /* company-management-hero end  */

  /* benefit-hero start  */
  .benefit-hero {
    padding-top: 40px;
  }
  .benefit-hero .fullwidth-heading{
    margin: 0 auto 30px;
  }
  .benefit-hero .fullwidth-heading .section-title {
    font-size: 14px;
  }
  .benefit-hero .fullwidth-heading .title {
    font-size: 32px;
    margin-bottom: 16px;
  }
  .benefit-hero .fullwidth-heading .desc {
    font-size: 14px;
    margin-bottom: -32px;
  }
  /* benefit-hero end  */

  /* visualization-and-navigation start  */
  .visualization-and-navigation .fullwidth-heading .title {
    font-size: 24px;
    margin-bottom: -34px;
  }
 /* visualization-and-navigation end  */
  /* structure-understand-accessibility start  */
  .structure-understand-accessibility .fullwidth-heading .title {
    font-size: 28px;
    margin-bottom: 16px;
  }
  /* structure-understand-accessibility end  */
  /* Real-Time Attendance Tracking start  */
 
}

