/* feture */
.future .feture-hero {
  background: url("./../images/hero/future-hero.png") no-repeat center;
  background-size: cover;
  overflow-x: hidden;
}

.future .feture-hero-title {
  width: calc(100% - 1rem);
}

.future .info-card {
  min-height: 340px;
}

.info-card {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.info-card:hover {
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  background: rgba(1, 63, 215, 0.04);
}

.future .section-desc {
  font-size: 20px;
  max-width: 852px;
}

.future .learnMoreBtn {
  position: relative;
}

.future .learnMoreBtn .learnMore {
  position: absolute;
  top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.future .learnMoreBtn .loading-spin {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.future .info-card:hover .learnMoreBtn .learnMore {
  visibility: hidden;
  opacity: 0;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.future .info-card:hover .learnMoreBtn .loading-spin {
  visibility: visible;
  opacity: 1;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

/* feture */

/* circle css */

.circle {
  height: calc(900px - -0rem);
  max-width: 100vh;
  margin-top: 24px;
  /* overflow: hidden; */
}

.circle .circle-part {
  max-width: 800px;
  max-height: 800px;
  width: 100%;
  height: 100%;
  margin: 32px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.circle .circle-center-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 33.829px rgba(1, 63, 215, 0.08);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  max-width: 170px;
  max-height: 170px;
  width: 100%;
  height: 100%;
}

.circle .circle-center-img {
  width: 100%;
  height: 100%;
  object-fit: none;
  background-color: #fff;
  border: 2.60223px solid #243757;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.circle .hw-inner {
  max-width: 430px;
  max-height: 430px;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px dashed #c3def6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.circle .hw-inner .hw-inner-item {
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  border: 0.86741px solid rgba(52, 109, 254, 0.24);
  box-shadow: 0px 20.8178px 41.6357px rgba(145, 158, 171, 0.16);
  width: 69.39px;
  height: 69.39px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
  transform: translate(16rem, 13rem);
  -webkit-transform: translate(16rem, 13rem);
  -moz-transform: translate(16rem, 13rem);
  -ms-transform: translate(16rem, 13rem);
  -o-transform: translate(16rem, 13rem);
}

.circle .hw-inner .hw-inner-item:nth-child(1) {
  transform: translate(21rem, 2rem);
  -webkit-transform: translate(21rem, 2rem);
  -moz-transform: translate(21rem, 2rem);
  -ms-transform: translate(21rem, 2rem);
  -o-transform: translate(21rem, 2rem);
}

.circle .hw-inner .hw-inner-item:nth-child(2) {
  transform: translate(22rem, 19rem);
  -webkit-transform: translate(22rem, 19rem);
  -moz-transform: translate(22rem, 19rem);
  -ms-transform: translate(22rem, 19rem);
  -o-transform: translate(22rem, 19rem);
}

.circle .hw-inner .hw-inner-item:nth-child(3) {
  transform: translate(0rem, 18rem);
  -webkit-transform: translate(0rem, 18rem);
  -moz-transform: translate(0rem, 18rem);
  -ms-transform: translate(0rem, 18rem);
  -o-transform: translate(0rem, 18rem);
}

.circle .hw-inner .hw-inner-item:nth-child(4) {
  transform: translate(0rem, 4rem);
  -webkit-transform: translate(0rem, 4rem);
  -moz-transform: translate(0rem, 4rem);
  -ms-transform: translate(0rem, 4rem);
  -o-transform: translate(0rem, 4rem);
}

.circle .hw-inner .hw-inner-item:nth-child(5) {
  transform: translate(13rem, 0rem);
  -webkit-transform: translate(13rem, 0rem);
  -moz-transform: translate(13rem, 0rem);
  -ms-transform: translate(13rem, 0rem);
  -o-transform: translate(13rem, 0rem);
}

/* hw-outer */
.circle .hw-outer {
  max-width: 650px;
  max-height: 650px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  border: 2px dashed #c3def6;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.circle .hw-outer .hw-outer-div {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.circle .hw-outer .hw-outer-item {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 69.39px;
  height: 69.39px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.86741px solid rgba(52, 109, 254, 0.24);
  box-shadow: 0px 20.8178px 41.6357px rgba(145, 158, 171, 0.16);
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

.circle .hw-outer .hw-outer-item:nth-child(1) {
  transform: translate(16rem, -2rem);
  -webkit-transform: translate(16rem, -2rem);
  -moz-transform: translate(16rem, -2rem);
  -ms-transform: translate(16rem, -2rem);
  -o-transform: translate(16rem, -2rem);
}

.circle .hw-outer .hw-outer-item:nth-child(2) {
  transform: translate(37rem, 12rem);
  -webkit-transform: translate(37rem, 12rem);
  -moz-transform: translate(37rem, 12rem);
  -ms-transform: translate(37rem, 12rem);
  -o-transform: translate(37rem, 12rem);
}

.circle .hw-outer .hw-outer-item:nth-child(3) {
  transform: translate(32rem, 32rem);
  -webkit-transform: translate(32rem, 32rem);
  -moz-transform: translate(32rem, 32rem);
  -ms-transform: translate(32rem, 32rem);
  -o-transform: translate(32rem, 32rem);
}

.circle .hw-outer .hw-outer-item:nth-child(4) {
  transform: translate(7rem, 35rem);
  -webkit-transform: translate(7rem, 35rem);
  -moz-transform: translate(7rem, 35rem);
  -ms-transform: translate(7rem, 35rem);
  -o-transform: translate(7rem, 35rem);
}

.circle .hw-outer .hw-outer-item:nth-child(5) {
  transform: translate(-1rem, 10rem);
  -webkit-transform: translate(-1rem, 10rem);
  -moz-transform: translate(-1rem, 10rem);
  -ms-transform: translate(-1rem, 10rem);
  -o-transform: translate(-1rem, 10rem);
}

.circle .hw-outer .hw-outer-item:nth-child(6) {
  transform: translate(3rem, 1rem);
  -webkit-transform: translate(3rem, 1rem);
  -moz-transform: translate(3rem, 1rem);
  -ms-transform: translate(3rem, 1rem);
  -o-transform: translate(3rem, 1rem);
}

.circle .hw-outer .hw-outer-item:nth-child(7) {
  transform: translate(14rem, -1rem);
  -webkit-transform: translate(14rem, -1rem);
  -moz-transform: translate(14rem, -1rem);
  -ms-transform: translate(14rem, -1rem);
  -o-transform: translate(14rem, -1rem);
}

.circle .animate-rotate-img {
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  object-fit: cover;
  scale: 0.5;
}

/* circle animation */
/* forward animate */
.animate-spin-slow {
  animation: spin-slow 1s linear infinite;
  -webkit-animation: spin-slow 1s linear infinite;
}

@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.anim-8s {
  animation-duration: 100s !important;
}

.animate-rotate-img {
  object-fit: cover;
  margin: 0 auto;
  width: calc(100% - 0%);
  -webkit-animation: rotate-img 30s linear infinite;
  animation: rotate-img 30s linear infinite;
}

@keyframes rotate-img {
  from {
    transform: rotate(-0deg);
    -webkit-transform: rotate(-0deg);
    -moz-transform: rotate(-0deg);
    -ms-transform: rotate(-0deg);
    -o-transform: rotate(-0deg);
  }

  to {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
  }
}

/* reverse animate */
.reverse-animate-spin-slow {
  animation: reverse-spin-slow 100s linear infinite;
  -webkit-animation: reverse-spin-slow 100s linear infinite;
}

@keyframes reverse-spin-slow {
  from {
    transform: rotate(-0deg);
    -webkit-transform: rotate(-0deg);
    -moz-transform: rotate(-0deg);
    -ms-transform: rotate(-0deg);
    -o-transform: rotate(-0deg);
  }

  to {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
  }
}

.reverse-animate-rotate-img {
  object-fit: cover;
  margin: 0 auto;
  width: calc(100% - 0%);
  scale: 0.5;
  animation: reverse-rotate-img 40s linear infinite;
  -webkit-animation: reverse-rotate-img 40s linear infinite;
}

@keyframes reverse-rotate-img {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

/* circle css */

/* key future */
.hrprocess {
  padding: 100px 0;
}

.hrprocess .info-card {
  padding: 32px 16px 28px;
  height: 100%;
}

/* key future */

/* payroll */
/* payroll-hero */
.payroll-hero {
  padding-top: 64px;
  background: url("./../images/feature/future-hero-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;
}

.payroll-hero .fullwidth-heading {
  max-width: 813px;
  margin: 0 auto 26px;
}

.payroll-hero .fullwidth-heading .section-name {
  font-weight: 700;
}

.payroll-hero .fullwidth-heading h2 {
  font-weight: 800;
  font-size: 64px;
  line-height: 77px;
}

.payroll-hero .fullwidth-heading h2 span {
  background: linear-gradient(89.93deg,
      #013fd7 1.89%,
      #117fea 8.68%,
      #01ccff 25.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.payroll-hero .fullwidth-heading .btn-group {
  gap: 10px;
}

.payroll-hero .fullwidth-heading .btn-group .btn {
  color: #fff !important;
  max-width: 200px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
}

.payroll-hero .fullwidth-heading .btn-group .btn:last-child {
  color: #243757 !important;
  border: 1px solid #243757;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.payroll-hero .fullwidth-heading .btn-group .btn:last-child svg path {
  stroke: #243757;
}

/* payroll-hero */
/* payroll-feature */
.payroll-feature {
  padding: 128px 0 96px;
}

.payroll-feature .fullwidth-heading {
  padding-bottom: 64px;
  max-width: 722px;
  margin: 0 auto;
}

.payroll-feature .fullwidth-heading h2 {
  font-size: 48px;
}

/* .payroll-feature .fullwidth-heading p {
  font-size: 12px;
} */

.payroll-feature .card {
  /* max-height: 440px;
  min-height: 179px; */
  height: 100%;
  /* height: auto; */
}

.payroll-feature .text-content {
  text-align: center;
}

.payroll-feature .text-content h3 {
  max-width: 80%;
  margin: 0 auto 1rem;
}

/* payroll-feature */
/* payroll-works */
.payroll-works {
  padding: 64px 0;
}

.payroll-works .fullwidth-heading {
  max-width: 684px;
  margin: 0 auto 64px;
}

.payroll-works .fullwidth-heading .desc {
  font-size: 12px;
}

.payroll-works .fullwidth-heading {}

.payroll-works .tab-section {
  background: rgba(237, 241, 248, 0.4);
  border: 1px solid #edf1f8;
  border-radius: 16px;
  padding: 20px 24px;
  max-width: 1116px;
  margin: 0 auto;
}

.payroll-works .tab-section .nav-tabs {
  justify-content: center;
  gap: 24px;
  border: 0px;
  margin-bottom: 78px;
  --bs-nav-tabs-border-width: 0px;
}

.payroll-works .tab-section .nav-tabs .nav-link {
  background: #edf1f8;
  border: 1px solid #dbe3f0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #525252;
  padding: 17px 23px;
}

.payroll-works .tab-section .nav-tabs .nav-link.active {
  background: #013fd7;
  color: #ffffff !important;
  border: 1px solid #dbe3f000;
}

.payroll-works .tab-section .tab-content h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: #243757;
}

.payroll-works .tab-section .tab-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #243757;
}

.payroll-works .tab-section .tab-content .img img {
  width: 100%;
}

/* payroll-works */
/* payroll-manage */
.payroll-manage {
  padding: 64px 0;
}

.payroll-manage .fullwidth-heading {
  max-width: 629px;
  margin: 0 auto 64px;
}

.payroll-manage .fullwidth-heading .desc {
  font-size: 12px;
}

/* payroll-manage */
/* payroll-revolutionize */
.payroll-revolutionize {
  padding: 117px 0;
  /* background: #f7f7f9; */
}

.payroll-revolutionize .hero-title .title {
  font-weight: 800;
  font-size: 40px;
}

.payroll-revolutionize .hero-title .desc {
  font-weight: 400;
  font-size: 16px;
}

.payroll-revolutionize .list_dots {
  padding-left: 20px;
}

.payroll-revolutionize .list_dots li {
  list-style-type: disc;
}

/* payroll-revolutionize */
/* payroll-benifits */
.payroll-benifits {
  padding: 64px 0;
}

.payroll-benifits .fullwidth-heading {
  max-width: 759px;
  margin: 0 auto 64px;
}

.payroll-benifits .fullwidth-heading .desc {
  font-size: 14px;
}

.payroll-benifits .info-card {
  min-height: 281px;
  background: #ffffff;
  border: 0.825949px solid rgba(91, 119, 147, 0.24);
  box-shadow: 0px 9.91139px 24.7785px rgba(145, 158, 171, 0.16);
  border-radius: 13.2152px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19.82px;
}

.payroll-benifits .info-card .img-content img {
  background: #ffffff;
  border: 0.714904px solid rgba(145, 158, 171, 0.24);
  box-shadow: 0px 17.1577px 34.3154px rgba(145, 158, 171, 0.16);
  border-radius: 11.4385px;
  /* padding: 12px; */
  max-width: 76px;
}

.payroll-benifits .info-card .text-content h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #212b36;
}

.payroll-benifits .info-card .text-content p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2d446c;
}

/* payroll-benifits */

/* payroll-action */
.payroll-action {
  padding: 160px 0;
  /* background: #f7f7f9; */
}

.payroll-action .fullwidth-heading .title {
  font-weight: 800;
  font-size: 40px;
  text-align: left;
}

.payroll-action .fullwidth-heading .desc {
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

/* payroll-action */

/* payroll-frequently-question */
.payroll-frequently-question {
  padding: 64px 0;
}

.payroll-frequently-question .accordion {
  --bs-accordion-border-width: 0;
}

.payroll-frequently-question .accordion .accordion-item {
  border-bottom: 1px solid #edf1f8;
}

.payroll-frequently-question .accordion .accordion-item .accordion-header .accordion-button {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #253858;
  z-index: 1;
}

/* payroll-frequently-question */

/* payroll */

/* notification */
/* notification-hero */
.notification-hero h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #013fd7;
}

.notification-hero .btn {
  max-width: 355px;
  width: 100% !important;
}

.notification-hero .img {
  max-width: 971px;
}

.notification-hero .img img {
  scale: 0.6;
}

/* notification-hero */
/* notification-feature */
.notification-feature {
  padding: 64px 0;
}

.notification-feature .fullwidth-heading {
  margin-bottom: 44px;
}

.notification-feature .fullwidth-heading .title {
  font-size: 40px;
}

.notification-feature .info-card {
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 19.82px;
  isolation: isolate;
}

.notification-feature .info-card .img-content img {
  max-width: 52.861px;
  max-height: 52.861px;
  background: #ffffff;
  border: 0.714904px solid rgba(145, 158, 171, 0.24);
  box-shadow: 0px 17.1577px 34.3154px rgba(145, 158, 171, 0.16);
  border-radius: 11.4385px;
  /* padding: 12px; */
}

/* notification-feature */

/* notification-coreHR */
.notification-coreHR {
  padding: 173px 0;
}

.notification-coreHR .heading h2 {
  text-align: left;
}

.notification-coreHR .heading p {
  text-align: left;
  margin-right: auto;
  margin-left: unset;
  margin-bottom: 33px;
  font-size: 16px;
  max-width: 100%;
}

.notification-coreHR .heading .hr-list-item ul {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 34px;
}

/* notification-coreHR */

/* Notification-online-service */
.Notification-online-service {
  background: #f7f7f9;
  padding: 120px 0;
}

.Notification-online-service .fullwidth-heading .title {
  text-align: left;
}

.Notification-online-service .fullwidth-heading .desc {
  text-align: left;
}

/* Notification-online-service */

/* everySituation */
.everySituation {
  padding: 64px 0;
}

.everySituation .fullwidth-heading h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  color: #243757;
  margin-bottom: 16px;
  text-align: left;
}

.everySituation .fullwidth-heading p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #243757;
}

.everySituation .img img {
  width: 100%;
}

/* everySituation */

/* notification-task */
.notification-task {
  background: #f7f7f9;
}

.notification-task img {
  width: 100%;
}

/* notification-task */

/* growing-part */
.growing-part .hero-title-animate {
  gap: 116px;
}

.growing-part .hero-title h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #243757;
}

.growing-part .hero-title .hr-list-item ul {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.growing-part .hero-title .hr-list-item ul li {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #243757;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.growing-part .hero-title .btn {
  padding: 13px 24px;
  width: max-content !important;
}

.growing-part .hero-title .btn.btn-light {
  margin-right: 12px;
}

.growing-part .hero-title .img {
  max-width: 1091px;
}

.growing-part .hero-title .img img {
  width: 100%;
}

/* growing-part */

/* notification-blockchain */
.notification-blockchain {
  padding: 95px 0;
}

.notification-blockchain .fullwidth-heading .title {
  text-align: left;
  font-size: 40px;
}

.notification-blockchain .fullwidth-heading .desc {
  text-align: left;
  font-size: 16px;
}

/* notification-blockchain */

/* notification */
/* domaincustomization */
/* domaincustomization-feature */
.domaincustomization-feature .fullwidth-heading {
  max-width: 926px;
}

.domaincustomization-feature .fullwidth-heading .title {
  font-size: 64px;
}

.domaincustomization-feature .fullwidth-heading .desc {
  font-size: 20px;
}

.domaincustomization-feature .img-content img {
  max-height: 300px;
}

/* domaincustomization-feature */

/* domaincustomization-security */
.domaincustomization-security .fullwidth-heading {
  max-width: 485px;
}

.domaincustomization-security .fullwidth-heading .title {
  font-weight: 800;
  font-size: 40px;
  line-height: 45px;
}

/* domaincustomization-security */
/* domaincustomization */
/* Leave Management */
/* leaveManagement-hero */

.leaveManagement-hero {
  padding-top: 64px;
  background: url("./../images/feature/leavemanagement-hero-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.leaveManagement-hero .fullwidth-heading {
  max-width: 813px;
  margin: 0 auto 26px;
}

.leaveManagement-hero .fullwidth-heading .section-name {
  font-weight: 700;
}

.leaveManagement-hero .fullwidth-heading h2 {
  font-weight: 800;
  font-size: 64px;
  line-height: 77px;
}

.leaveManagement-hero .btn-group .btn {
  max-width: 200px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
}

.leaveManagement-hero .btn-group .btn:last-child {
  color: #243757;
  border: 1px solid #243757;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* leaveManagement-hero */

/* leaveManagement-system */
.leaveManagement-system .fullwidth-heading {
  max-width: 686px;
}

.leaveManagement-system h2 {
  font-weight: 700 !important;
  font-size: 40px;
  line-height: 56px;
  color: #243757;
}

.leaveManagement-system p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #475569;
}

/* leaveManagement-system */

/* leaveManagement-control */
.leaveManagement-control .fullwidth-heading {
  max-width: 725px;
}

.leaveManagement-control h2 {
  font-size: 48px;
  line-height: 66px;
}

.leaveManagement-control .img {
  max-width: 926px;
  margin: 0 auto;
}

.leaveManagement-control .img img {
  width: 100%;
  image-rendering: optimizeQuality;
}

/* leaveManagement-control */

/* leaveManagement-wellbeing */
.leaveManagement-wellbeing {
  /* background: rgba(237, 241, 248, 0.4); */
}

.leaveManagement-wellbeing .fullwidth-heading {
  max-width: 485px;
}

/* leaveManagement-wellbeing */

/* leaveManagement-takeContol */
.leaveManagement-takeContol {
  padding: 64px 0;
}

.leaveManagement-takeContol .fullwidth-heading .title {
  text-align: left;
}

.leaveManagement-takeContol .fullwidth-heading .btn-group {
  margin-top: 0 !important;
}

/* leaveManagement-takeContol */

/* leaveManagement-Workweeks */
.leaveManagement-Workweeks .fullwidth-heading .title {
  /* fw-800 fs-40 */
  text-align: left;
}

.leaveManagement-Workweeks .fullwidth-heading .desc {
  /* fs-16 fw-400 */
  text-align: left;
}

.leaveManagement-Workweeks .fullwidth-heading .btn-group {
  margin-top: 0 !important;
}

/* leaveManagement-Workweeks */

/* leaveManagement-blockchain */
.leaveManagement-blockchain {
  background: #243757;
  padding: 156px 0;
}

.leaveManagement-blockchain .heading {
  max-width: 546.85px;
}

.leaveManagement-blockchain .heading h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  text-align: left;
  color: #ffffff;
}

.leaveManagement-blockchain .heading p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #ffffff;
  margin-right: auto;
  margin-left: unset;
  margin-bottom: 48px;
}

.leaveManagement-blockchain .btn {
  background: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #013fd7;
}

/* leaveManagement-blockchain */

/* leaveManagement-teamCalender */
.leaveManagement-teamCalender {
  padding: 90px 0;
}

.leaveManagement-teamCalender .heading h2 {
  text-align: left;
}

.leaveManagement-teamCalender .heading p {
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin-right: auto;
  margin-left: unset;
  margin-bottom: 48px;
  max-width: fit-content;
}

.leaveManagement-teamCalender .heading .btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #013fd7;
  border-radius: 8px;
  border: 1px solid #346dfe;
}

/* leaveManagement-teamCalender */

/* leaveManagement-teamCalender */
.leaveManagement-teamCalender {
  padding: 71px 0;
}

.leaveManagement-teamCalender .heading .btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #013fd7;
  border-radius: 8px;
  border: 1px solid #346dfe;
}

/* leaveManagement-teamCalender */
/* Leave Management */

/* shift Management */
/* shiftManagement-hero */
.shiftManagement-hero {
  padding: 115px 0;
}

/* shiftManagement-hero */

/* shiftManagement-reduce */
.shiftManagement-reduce {
  padding-top: 73px;
  padding-bottom: 9px;
  /* height: 1438px; */
  background: url("./../images/feature/reduce-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.shiftManagement-reduce::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: url("./../images/feature/curve.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.shiftManagement-reduce .schedule-card {
  position: relative;
  /* margin-bottom: 121px; */
  height: calc(100% - 0rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shiftManagement-reduce .schedule-card::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  background: rgba(0, 0, 0, 0.05);
  height: 7px;
  width: calc(100% - 3%);
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  border-radius: 0px 0px 9.1282px 9.1282px;
  -webkit-border-radius: 0px 0px 9.1282px 9.1282px;
  -moz-border-radius: 0px 0px 9.1282px 9.1282px;
  -ms-border-radius: 0px 0px 9.1282px 9.1282px;
  -o-border-radius: 0px 0px 9.1282px 9.1282px;
}

.shiftManagement-reduce .fullwidth-heading {
  margin-top: 121px;
}

.shiftManagement-reduce .fullwidth-heading .desc {
  font-size: 18px;
  max-width: 629px;
  margin: 0 auto;
}

.shiftManagement-reduce .fullwidth-heading .img {
  margin-top: 62px;
}

/* .shiftManagement-reduce .img {
  background: #ffffff;
  box-shadow: 0px 0px 2.62606px rgba(0, 0, 0, 0.24),
    -26.2606px 26.2606px 52.5212px -5.25212px rgba(174, 174, 174, 0.24);
  border-radius: 15.7563px;
} */

/* shiftManagement-reduce */

/* shiftManagement-analytics */
.shiftManagement-analytics {
  padding: 64px 0;
}

.shiftManagement-analytics .fullwidth-heading {
  max-width: 736px;
  margin: 0 auto;
}

.shiftManagement-analytics .fullwidth-heading .section-title {
  font-weight: 700;
}

.shiftManagement-analytics .fullwidth-heading .title {
  font-weight: 700;
  font-size: 40px;
}

.shiftManagement-analytics .fullwidth-heading .desc {
  font-weight: 400;
  font-size: 16px;
}

.shiftManagement-analytics .info-card {
  align-items: center;
  background: #fff;
  border: 0.825949px solid rgba(91, 119, 147, 0.24);
  border-radius: 13.2152px;
  box-shadow: 0 9.91139px 24.7785px rgba(145, 158, 171, 0.16);
  display: flex;
  flex-direction: column;
  gap: 19.82px;
  justify-content: center;
  min-height: 281px;
}

.shiftManagement-analytics .info-card .img-content img {
  max-width: 100px;
}

/* shiftManagement-analytics */

/* customer-solution */
.customer-solution {
  padding: 64px 0 128px;
}

.customer-solution h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  color: #243757;
  margin-bottom: 64px;
}

.customer-solution .solution-item {
  padding-right: 25%;
  position: relative;
  border-bottom: 2px solid rgba(7, 16, 25, 0.1);
  margin-bottom: 48px;
}

.customer-solution .solution-item:nth-child(1):after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  width: 80%;
  height: 2px;
  background: #013fd7;
}

.customer-solution .solution-item h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #243757;
}

.customer-solution .solution-item p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(7, 16, 25, 0.75);
  margin-bottom: 48px;
}

/* customer-solution */

/* theGlobe */
.theGlobe .heading {
  margin-bottom: 70px;
}

.theGlobe .heading div span {
  color: #72b362;
}

/* theGlobe */

/* shiftManagement-employee-focused */
.shiftManagement-employee-focused {
  padding-bottom: 67px;
}

.shiftManagement-employee-focused .fullwidth-heading {
  max-width: 800px;
  margin: 0 auto 64px;
}

/* shiftManagement-employee-focused */

/* shiftManagement-blockchain */
.shiftManagement-blockchain {
  padding: 117px 0;
}

.shiftManagement-blockchain .hero-title h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  color: #243757;
}

.shiftManagement-blockchain .hero-title p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #243757;
}

/* shiftManagement-blockchain */

/* shiftManagement-schedule */
.shiftManagement-schedule {
  padding: 80px 0 64px;
}

.shiftManagement-schedule .hero-title h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #243757;
}

.shiftManagement-schedule .hero-title p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #243757;
}

/* shiftManagement-schedule */
/* shift Management */

/* Attendance Management */
/* AttendanceManagement-hero */
.AttendanceManagement-hero {
  padding: 80px 0;
}

.AttendanceManagement-hero .hero-title {
  max-width: 546px;
}

/* AttendanceManagement-hero */

/* AttendanceManagement-benifit */
.AttendanceManagement-benifits .fullwidth-heading {
  max-width: fit-content;
  margin: 0 auto;
}

.AttendanceManagement-benifits .fullwidth-heading .desc {
  max-width: 640px;
  margin: 0 auto;
  font-size: 16px;
}

/* AttendanceManagement-benifit */

/* AttendanceManagement-employee */
.AttendanceManagement-employee {
  padding: 64px 0;
}

.AttendanceManagement-employee h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
}

/* AttendanceManagement-employee */

/* AttendanceManagement-biometric */
.AttendanceManagement-biometric {
  padding: 64px 0;
}

.AttendanceManagement-biometric .fullwidth-heading {
  margin: 0 auto;
}

.AttendanceManagement-biometric .fullwidth-heading .title {
  font-weight: 700;
  font-size: 36px;
}

.AttendanceManagement-biometric .fullwidth-heading .desc {
  font-size: 16px;
}

.AttendanceManagement-biometric .info-card {
  border: 0 !important;
  box-shadow: none;
  min-height: unset;
  background: transparent;
}

.AttendanceManagement-biometric .info-card .img-content img {
  border: 0;
  box-shadow: none;
  background: transparent;
}

/* AttendanceManagement-biometric */

/* AttendanceManagement-employeeabsent */
.AttendanceManagement-employeeabsent {
  padding: 64px 0;
}

.AttendanceManagement-employeeabsent .heading {
  max-width: 716px;
  margin: 0 auto;
}

.AttendanceManagement-employeeabsent .heading h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  color: #243757;
}

/* AttendanceManagement-employeeabsent */

/* AttendanceManagement-task */
.AttendanceManagement-task {
  padding: 64px 0;
}

.AttendanceManagement-task h2 {
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -0.275px;
  color: #222525;
}

.AttendanceManagement-task p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #222525;
}

/* AttendanceManagement-task */

/* AttendanceManagement-timeTracking */
.AttendanceManagement-timeTracking {
  padding: 64px 0;
}

.AttendanceManagement-timeTracking .fullwidth-heading .section-title {
  text-align: left;
}

.AttendanceManagement-timeTracking .fullwidth-heading .title {
  font-weight: 700;
  font-size: 42px;
  line-height: 57px;
  letter-spacing: -0.275px;
  color: #222525;
  text-align: left;
}

.AttendanceManagement-timeTracking .fullwidth-heading .time-tracking-part h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
}

.AttendanceManagement-timeTracking .fullwidth-heading .time-tracking-part p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #787878;
}

/* AttendanceManagement-timeTracking */

/* AttendanceManagement-blockchain */
.AttendanceManagement-blockchain {
  padding: 117px 0;
  background: #f7f7f9;
}

/* AttendanceManagement-blockchain */
/* Attendance Management */

/* NoticeBoard */
/* .NoticeBoard-creation */
.NoticeBoard-creation .fullwidth-heading .btn-group {
  margin-top: 0 !important;
}

/* .NoticeBoard-creation */
/* NoticeBoard-management */

.NoticeBoard-management .schedule-card {
  position: relative;
}

.NoticeBoard-management .schedule-card::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: calc(100% - 1rem);
  height: 7px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 9.1282px 9.1282px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

/* NoticeBoard-management */

/* NoticeBoardBlockchain */
.NoticeBoard-blockchain .fullwidth-heading .title {
  font-weight: 800;
  font-size: 40px;
  text-align: left;
  margin-bottom: 24px;
}

.NoticeBoard-blockchain .fullwidth-heading .desc {
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

/* NoticeBoardBlockchain */

/* NoticeBoard-friendlyInterface */
.NoticeBoard-friendlyInterface {
  padding-top: 97px;
}

.NoticeBoard-friendlyInterface .hr-list-item ul li {
  display: flex;
  gap: 10px;
}

/* NoticeBoard-friendlyInterface */

/* NoticeBoard-attachfile */
.NoticeBoard-attachfile {
  padding-top: 120px;
}

.NoticeBoard-attachfile img {
  width: 100%;
}

.NoticeBoard-attachfile .fullwidth-heading h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  color: #243757;
  text-align: left;
}

.NoticeBoard-attachfile .fullwidth-heading p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #243757;
  text-align: left;
}

/* NoticeBoard-attachfile */
/* NoticeBoard */

/* TaxManagement */
/* TaxManagement-hero */
.TaxManagement-hero {
  padding: 64px 0 92px;
  background: url("./../images/feature/taxmanagement-hero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.TaxManagement-hero .heading {
  max-width: 926px;
  margin: 0 auto 64px;
}

.TaxManagement-hero .heading h2 {
  font-weight: 800;
  font-size: 60px;
  line-height: 77px;
}

.TaxManagement-hero .heading p {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.TaxManagement-hero .heading .btn-group {
  max-width: 355px;
  margin: 0 auto;
}

/* TaxManagement-hero */

/* TaxManagement-feature */
.TaxManagement-feature .fullwidth-heading {
  max-width: 926px;
}

.TaxManagement-feature .fullwidth-heading .title {
  font-weight: 800;
  font-size: 64px;
}

.TaxManagement-feature .fullwidth-heading .desc {
  font-weight: 500;
  font-size: 20px;
}

.TaxManagement-feature .img-content {
  border-radius: 3.5418px;
  position: relative;
  overflow: hidden;
}

.TaxManagement-feature .img-content .video {
  width: 100%;
  height: 100%;
}

.TaxManagement-feature .img-content .playBtn {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg,
      rgba(36, 55, 87, 0.1) 0%,
      rgba(36, 55, 87, 0.012) 100%);
  /* box-shadow: 0px 1.29993px 12.9993px rgba(36, 55, 87, 0.12); */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TaxManagement-feature .img-content .playBtn img {
  cursor: pointer;
}

.TaxManagement-feature .text-content h3 {
  max-width: 100%;
  margin-bottom: 1rem;
  margin-left: unset;
  margin-right: unset;
}

/* TaxManagement-feature */

/* TaxManagement-tax */
.TaxManagement-tax .fullwidth-heading {
  max-width: 926px;
  margin: 0 auto;
}

.TaxManagement-tax .fullwidth-heading .title {
  font-weight: 800;
  font-size: 64px;
}

.TaxManagement-tax .fullwidth-heading .desc {
  font-weight: 500;
  font-size: 20px;
}

.TaxManagement-tax .tab-section .tab-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.5px;
  color: #243757;
  margin-bottom: 48px;
}

.TaxManagement-tax .tab-section .nav-tabs {
  gap: 0;
}

.TaxManagement-tax .tab-section .nav-tabs li {
  width: 100%;
}

.TaxManagement-tax .tab-section .nav-tabs li .nav-link {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #013fd7 !important;
  padding: 16px 10px;
  border-radius: 4px;
}

.TaxManagement-tax .tab-section .nav-tabs li .nav-link.active {
  background: #e6edff;
  color: #243757 !important;
}

.TaxManagement-tax .tab-content .tab-pane h6 {
  color: #243757;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
}

.TaxManagement-tax .tab-content .tab-pane p {
  color: #243757;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: 0.5px;
}

.TaxManagement-tax .tab-content .tab-pane ul {
  padding-left: 2rem;
}

.TaxManagement-tax .tab-content .tab-pane ul li {
  list-style-type: disc;
}

/* TaxManagement-tax */

/* TaxManagement-blockchain */
.TaxManagement-blockchain .fullwidth-heading .title {
  text-align: left;
  font-weight: 800;
  font-size: 40px;
}

.TaxManagement-blockchain .fullwidth-heading .desc {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
}

.TaxManagement-blockchain .fullwidth-heading .btn {
  max-width: 220px;
  margin-right: auto;
}

.TaxManagement-blockchain .btn-group {
  justify-content: flex-start !important;
}

/* TaxManagement-blockchain */

/* TaxManagement-taxCalculation */
.TaxManagement-taxCalculation ul li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #243757;
}

/* TaxManagement-taxCalculation */
/* TaxManagement */

/* BlockchainSecurity */
/* blockSecurity-hero */
.blockSecurity-hero {
  padding: 190px 0;
  background: url("./../../assets/images/feature/blockchain/block-chain-page-banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blockSecurity-hero h2,
.blockSecurity-hero p{
  color: white;
}

/* blockSecurity-hero */
/* blockSecurity-integrate */
.blockSecurity-integrate .content-card .img-content {
  max-width: 48px;
  max-height: 48px;
  margin: 0 auto;
}

.blockSecurity-integrate {}

.blockSecurity-integrate {}

.blockSecurity-integrate {}

.blockSecurity-integrate {}

.blockSecurity-integrate {}

/* blockSecurity-integrate */
/* BlockchainSecurity */

/* EmployeeMonitoring */
/* employeeMonitoring-hero */
.employeeMonitoring-hero {
  padding-top: 64px;
}

.employeeMonitoring-hero .fullwidth-heading {
  max-width: 926px;
  margin: 0 auto 66px;
}

.employeeMonitoring-hero .fullwidth-heading h2 {
  font-size: 64px;
}

.employeeMonitoring-hero .fullwidth-heading p {
  font-weight: 500;
  font-size: 20px;
}

.employeeMonitoring-hero .fullwidth-heading .btn-group {
  max-width: 355px;
  margin: 0 auto;
}

/* employeeMonitoring-hero */

/* EmployeeMonitoring-integrate */
.EmployeeMonitoring-integrate .fullwidth-heading {
  max-width: 926px;
}

.EmployeeMonitoring-integrate .fullwidth-heading .title {
  max-width: fit-content;
}

.EmployeeMonitoring-integrate .fullwidth-heading .desc {
  max-width: 852px;
  font-size: 20px;
}

.EmployeeMonitoring-integrate .img {
  max-width: 926px;
  margin: 0 auto 60px;
}

.EmployeeMonitoring-integrate .img img {
  width: 100%;
  object-fit: cover;
  image-rendering: optimizeQuality;
}

/* EmployeeMonitoring-integrate */

.employee .hr-list-item ul li {
  display: inline-flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #243757;
}

.employee .hr-list-item ul li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-size: contain;
  background-image: url("./../images/feature/solid-mark.png");
  background-repeat: no-repeat;
  margin-right: 10px;
  background-position: center center;
  vertical-align: middle;
  flex: 0 0 auto;
}

/* EmployeeMonitoring */

/* BlockchainSecurity */
/* trusted-platform */
.trusted-platform .content-card {
  text-align: center;
}

.trusted-platform .content-card img {
  width: auto !important;
  margin-bottom: 26px;
}

.trusted-platform .content-card .text-content {
  margin-left: auto !important;
  margin-right: auto !important;
}

.trusted-platform .content-card .text-content h2 {
  text-align: center;
  min-height: 48px;
}

.trusted-platform .content-card .text-content p {
  text-align: center;
}

/* trusted-platform */
/* blockSecurity-decentraliazation */
.blockSecurity-decentraliazation {
  padding: 69px 0;
}

.blockSecurity-decentraliazation .fullwidth-heading .section-title {
  text-align: left;
}

.blockSecurity-decentraliazation .fullwidth-heading .title {
  text-align: left;
  font-size: 40px;
  margin-bottom: 24px;
}

.blockSecurity-decentraliazation .fullwidth-heading .desc {
  text-align: left;
}

/* blockSecurity-decentraliazation */

/* blockSecurity-secureIdentity */

.blockSecurity-accessManagement {
  padding: 118px 0;
}

.blockSecurity-dataProtection {
  padding: 118px 0;
}

.blockSecurity-secureIdentity .fullwidth-heading .title {
  font-size: 40px;
  text-align: left;
  margin-bottom: 24px;
}

.blockSecurity-secureIdentity .fullwidth-heading .desc {
  font-size: 16px;
  text-align: left;
}

/* blockSecurity-secureIdentity */

/* blockSecurity-CommunicateEasilly */

.blockSecurity-CommunicateEasilly {
  padding: 69px 0;
}

.blockSecurity-CommunicateEasilly .fullwidth-heading .section-title {
  text-align: left;
}

.blockSecurity-CommunicateEasilly .fullwidth-heading .title {
  text-align: left;
  font-size: 40px;
  margin-bottom: 24px;
}

.blockSecurity-CommunicateEasilly .fullwidth-heading .desc {
  text-align: left;
}

/* blockSecurity-CommunicateEasilly */

/* blockSecurity-management */
.blockSecurity-management .content-card .text-content {
  margin-left: unset;
  margin-right: auto;
  max-width: 100%;
}

.blockSecurity-management .content-card img {
  width: 100%;
}

.blockSecurity-management .content-card h2 {
  text-align: left;
}

.blockSecurity-management .content-card p {
  text-align: left;
}

/* blockSecurity-management */
/* BlockchainSecurity */

/* .domaincustomization-hero */
.domainHero {
  padding-top: 64px;
  padding-bottom: 64px;
}

.domainHero .heading .fullwidth-heading .btn-group a.btn {
  color: #fff !important;
  max-width: 355px;
  width: 100%;
}

.domainHero .fullwidth-heading {
  max-width: 813px;
  margin: 0 auto 4rem;
}

.domainHero .fullwidth-heading .section-name {
  font-weight: 700;
}

.domainHero .fullwidth-heading h2 {
  font-weight: 800;
  font-size: 64px;
  line-height: 77px;
}

.domainHero .fullwidth-heading h2 span {
  background: linear-gradient(89.93deg,
      #013fd7 1.89%,
      #117fea 8.68%,
      #01ccff 25.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.domainHero .fullwidth-heading .btn-group {
  gap: 10px;
}

.domainHero .fullwidth-heading .btn-group .btn {
  color: #fff !important;
  max-width: 200px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
}

/* domaincustomization-hero */

/* domaincustomization-security */
.domaincustomization-security {
  padding-top: 92px;
  padding-bottom: 64px;
}

.domaincustomization-security .heading .fullwidth-heading .btn-group a.btn {
  color: #fff !important;
  max-width: 355px;
  width: 100%;
}

.domaincustomization-security .fullwidth-heading {
  max-width: 485px;
  margin: 0 auto 26px;
}

.domaincustomization-security .fullwidth-heading .section-name {
  font-weight: 700;
}

.domaincustomization-security .fullwidth-heading h2 {
  font-weight: 800;
  font-size: 64px;
  line-height: 77px;
}

.domaincustomization-security .fullwidth-heading h2 span {
  background: linear-gradient(89.93deg,
      #013fd7 1.89%,
      #117fea 8.68%,
      #01ccff 25.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.domaincustomization-security .fullwidth-heading .btn-group {
  gap: 10px;
}

.domaincustomization-security .fullwidth-heading .btn-group .btn {
  color: #fff !important;
  max-width: 200px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.25px;
}

/* domaincustomization-security */

/* employee-monitoring */
.EmployeeMonitoring-integrate .content-card .img-content img {
  max-width: 70px;
  width: 100%;
}

.EmployeeMonitoring-integrate {}

.EmployeeMonitoring-integrate {}

.EmployeeMonitoring-integrate {}

.blockSecurity-TrackingReport{
  padding: 0;
}

.TaxManagement-salaryCalculation{
  padding-top: 0;
}

/* employee-monitoring */

/* responsive */
@media (min-width: 1200px) and (max-width: 1470px) {
  .circle {
    height: calc(649px - 0rem);
  }

  .circle .circle-part {
    max-width: 500px;
    max-height: 500px;
  }

  .circle .circle-center-img-div {
    max-width: 130px;
    max-height: 130px;
  }

  .circle .hw-inner {
    max-width: 330px;
    max-height: 330px;
  }

  .circle .hw-inner .hw-inner-item {
    width: 50px;
    height: 50px;
  }

  .circle .hw-inner .hw-inner-item:nth-child(1) {
    transform: translate(16rem, 2rem);
    -webkit-transform: translate(16rem, 2rem);
    -moz-transform: translate(16rem, 2rem);
    -ms-transform: translate(16rem, 2rem);
    -o-transform: translate(16rem, 2rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(2) {
    transform: translate(15rem, 16rem);
    -webkit-transform: translate(15rem, 16rem);
    -moz-transform: translate(15rem, 16rem);
    -ms-transform: translate(15rem, 16rem);
    -o-transform: translate(15rem, 16rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(3) {
    transform: translate(1rem, 15rem);
    -webkit-transform: translate(1rem, 15rem);
    -moz-transform: translate(1rem, 15rem);
    -ms-transform: translate(1rem, 15rem);
    -o-transform: translate(1rem, 15rem);
  }

  .circle .hw-outer {
    max-width: 450px;
    max-height: 450px;
  }

  .circle .hw-outer .hw-outer-item {
    width: 50px;
    height: 50px;
    padding: 5px;
  }

  .circle .hw-outer .hw-outer-item .reverse-animate-rotate-img {
    /* width: calc(100% - 10px);
    height: calc(100% - 10px); */
    object-fit: cover;
  }

  .circle .hw-outer .hw-outer-item:nth-child(1) {
    transform: translate(9rem, -1.5rem);
    -webkit-transform: translate(9rem, -1.5rem);
    -moz-transform: translate(9rem, -1.5rem);
    -ms-transform: translate(9rem, -1.5rem);
    -o-transform: translate(9rem, -1.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(2) {
    transform: translate(25.5rem, 7rem);
    -webkit-transform: translate(25.5rem, 7rem);
    -moz-transform: translate(25.5rem, 7rem);
    -ms-transform: translate(25.5rem, 7rem);
    -o-transform: translate(25.5rem, 7rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(3) {
    transform: translate(22rem, 22.5rem);
    -webkit-transform: translate(22rem, 22.5rem);
    -moz-transform: translate(22rem, 22.5rem);
    -ms-transform: translate(22rem, 22.5rem);
    -o-transform: translate(22rem, 22.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(4) {
    transform: translate(6rem, 24.5rem);
    -webkit-transform: translate(6rem, 24.5rem);
    -moz-transform: translate(6rem, 24.5rem);
    -ms-transform: translate(6rem, 24.5rem);
    -o-transform: translate(6rem, 24.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(5) {
    transform: translate(-1rem, 9rem);
    -webkit-transform: translate(-1rem, 9rem);
    -moz-transform: translate(-1rem, 9rem);
    -ms-transform: translate(-1rem, 9rem);
    -o-transform: translate(-1rem, 9rem);
  }
}

@media (min-width: 1126px) and (max-width: 1199px) {
  .circle {
    height: calc(649px - 0rem);
  }

  .circle .circle-part {
    max-width: 500px;
    max-height: 500px;
  }

  .circle .circle-center-img-div {
    max-width: 130px;
    max-height: 130px;
  }

  .circle .hw-inner {
    max-width: 330px;
    max-height: 330px;
  }

  .circle .hw-inner .hw-inner-item {
    width: 50px;
    height: 50px;
  }

  .circle .hw-inner .hw-inner-item:nth-child(1) {
    transform: translate(16rem, 2rem);
    -webkit-transform: translate(16rem, 2rem);
    -moz-transform: translate(16rem, 2rem);
    -ms-transform: translate(16rem, 2rem);
    -o-transform: translate(16rem, 2rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(2) {
    transform: translate(15rem, 16rem);
    -webkit-transform: translate(15rem, 16rem);
    -moz-transform: translate(15rem, 16rem);
    -ms-transform: translate(15rem, 16rem);
    -o-transform: translate(15rem, 16rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(3) {
    transform: translate(1rem, 15rem);
    -webkit-transform: translate(1rem, 15rem);
    -moz-transform: translate(1rem, 15rem);
    -ms-transform: translate(1rem, 15rem);
    -o-transform: translate(1rem, 15rem);
  }

  .circle .hw-outer {
    max-width: 450px;
    max-height: 450px;
  }

  .circle .hw-outer .hw-outer-item {
    width: 50px;
    height: 50px;
    padding: 5px;
  }

  .circle .hw-outer .hw-outer-item .reverse-animate-rotate-img {
    /* width: calc(100% - 10px);
    height: calc(100% - 10px); */
    object-fit: cover;
  }

  .circle .hw-outer .hw-outer-item:nth-child(1) {
    transform: translate(9rem, -1.5rem);
    -webkit-transform: translate(9rem, -1.5rem);
    -moz-transform: translate(9rem, -1.5rem);
    -ms-transform: translate(9rem, -1.5rem);
    -o-transform: translate(9rem, -1.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(2) {
    transform: translate(25.5rem, 7rem);
    -webkit-transform: translate(25.5rem, 7rem);
    -moz-transform: translate(25.5rem, 7rem);
    -ms-transform: translate(25.5rem, 7rem);
    -o-transform: translate(25.5rem, 7rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(3) {
    transform: translate(22rem, 22.5rem);
    -webkit-transform: translate(22rem, 22.5rem);
    -moz-transform: translate(22rem, 22.5rem);
    -ms-transform: translate(22rem, 22.5rem);
    -o-transform: translate(22rem, 22.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(4) {
    transform: translate(6rem, 24.5rem);
    -webkit-transform: translate(6rem, 24.5rem);
    -moz-transform: translate(6rem, 24.5rem);
    -ms-transform: translate(6rem, 24.5rem);
    -o-transform: translate(6rem, 24.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(5) {
    transform: translate(-1rem, 9rem);
    -webkit-transform: translate(-1rem, 9rem);
    -moz-transform: translate(-1rem, 9rem);
    -ms-transform: translate(-1rem, 9rem);
    -o-transform: translate(-1rem, 9rem);
  }

  .hrprocess .col-lg-4.mb-5 {
    margin-bottom: 1.5rem !important;
  }

  .hrprocess .info-card {
    padding: 0px 5px;
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1126px) {
  .circle {
    height: calc(649px - 0rem);
  }

  .circle .circle-part {
    max-width: 500px;
    max-height: 500px;
  }

  .circle .circle-center-img-div {
    max-width: 130px;
    max-height: 130px;
  }

  .circle .hw-inner {
    max-width: 330px;
    max-height: 330px;
  }

  .circle .hw-inner .hw-inner-item {
    width: 50px;
    height: 50px;
  }

  .circle .hw-inner .hw-inner-item:nth-child(1) {
    transform: translate(16rem, 2rem);
    -webkit-transform: translate(16rem, 2rem);
    -moz-transform: translate(16rem, 2rem);
    -ms-transform: translate(16rem, 2rem);
    -o-transform: translate(16rem, 2rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(2) {
    transform: translate(15rem, 16rem);
    -webkit-transform: translate(15rem, 16rem);
    -moz-transform: translate(15rem, 16rem);
    -ms-transform: translate(15rem, 16rem);
    -o-transform: translate(15rem, 16rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(3) {
    transform: translate(1rem, 15rem);
    -webkit-transform: translate(1rem, 15rem);
    -moz-transform: translate(1rem, 15rem);
    -ms-transform: translate(1rem, 15rem);
    -o-transform: translate(1rem, 15rem);
  }

  .circle .hw-outer {
    max-width: 450px;
    max-height: 450px;
  }

  .circle .hw-outer .hw-outer-item {
    width: 50px;
    height: 50px;
    padding: 5px;
  }

  .circle .hw-outer .hw-outer-item .reverse-animate-rotate-img {
    /* width: calc(100% - 10px);
    height: calc(100% - 10px); */
    object-fit: cover;
  }

  .circle .hw-outer .hw-outer-item:nth-child(1) {
    transform: translate(9rem, -1.5rem);
    -webkit-transform: translate(9rem, -1.5rem);
    -moz-transform: translate(9rem, -1.5rem);
    -ms-transform: translate(9rem, -1.5rem);
    -o-transform: translate(9rem, -1.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(2) {
    transform: translate(25.5rem, 7rem);
    -webkit-transform: translate(25.5rem, 7rem);
    -moz-transform: translate(25.5rem, 7rem);
    -ms-transform: translate(25.5rem, 7rem);
    -o-transform: translate(25.5rem, 7rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(3) {
    transform: translate(22rem, 22.5rem);
    -webkit-transform: translate(22rem, 22.5rem);
    -moz-transform: translate(22rem, 22.5rem);
    -ms-transform: translate(22rem, 22.5rem);
    -o-transform: translate(22rem, 22.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(4) {
    transform: translate(6rem, 24.5rem);
    -webkit-transform: translate(6rem, 24.5rem);
    -moz-transform: translate(6rem, 24.5rem);
    -ms-transform: translate(6rem, 24.5rem);
    -o-transform: translate(6rem, 24.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(5) {
    transform: translate(-1rem, 9rem);
    -webkit-transform: translate(-1rem, 9rem);
    -moz-transform: translate(-1rem, 9rem);
    -ms-transform: translate(-1rem, 9rem);
    -o-transform: translate(-1rem, 9rem);
  }

  .hrprocess .col-lg-4.mb-5 {
    margin-bottom: 1.5rem !important;
  }

  .hrprocess .info-card {
    padding: 0px 5px;
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .circle {
    height: calc(649px - 0rem);
  }

  .circle .circle-part {
    max-width: 500px;
    max-height: 500px;
  }

  .circle .circle-center-img-div {
    max-width: 130px;
    max-height: 130px;
  }

  .circle .hw-inner {
    max-width: 330px;
    max-height: 330px;
  }

  .circle .hw-inner .hw-inner-item {
    width: 50px;
    height: 50px;
  }

  .circle .hw-inner .hw-inner-item:nth-child(1) {
    transform: translate(16rem, 2rem);
    -webkit-transform: translate(16rem, 2rem);
    -moz-transform: translate(16rem, 2rem);
    -ms-transform: translate(16rem, 2rem);
    -o-transform: translate(16rem, 2rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(2) {
    transform: translate(15rem, 16rem);
    -webkit-transform: translate(15rem, 16rem);
    -moz-transform: translate(15rem, 16rem);
    -ms-transform: translate(15rem, 16rem);
    -o-transform: translate(15rem, 16rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(3) {
    transform: translate(1rem, 15rem);
    -webkit-transform: translate(1rem, 15rem);
    -moz-transform: translate(1rem, 15rem);
    -ms-transform: translate(1rem, 15rem);
    -o-transform: translate(1rem, 15rem);
  }

  .circle .hw-outer {
    max-width: 450px;
    max-height: 450px;
  }

  .circle .hw-outer .hw-outer-item {
    width: 50px;
    height: 50px;
    padding: 5px;
  }

  .circle .hw-outer .hw-outer-item .reverse-animate-rotate-img {
    /* width: calc(100% - 10px);
    height: calc(100% - 10px); */
    object-fit: cover;
  }

  .circle .hw-outer .hw-outer-item:nth-child(1) {
    transform: translate(9rem, -1.5rem);
    -webkit-transform: translate(9rem, -1.5rem);
    -moz-transform: translate(9rem, -1.5rem);
    -ms-transform: translate(9rem, -1.5rem);
    -o-transform: translate(9rem, -1.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(2) {
    transform: translate(25.5rem, 7rem);
    -webkit-transform: translate(25.5rem, 7rem);
    -moz-transform: translate(25.5rem, 7rem);
    -ms-transform: translate(25.5rem, 7rem);
    -o-transform: translate(25.5rem, 7rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(3) {
    transform: translate(22rem, 22.5rem);
    -webkit-transform: translate(22rem, 22.5rem);
    -moz-transform: translate(22rem, 22.5rem);
    -ms-transform: translate(22rem, 22.5rem);
    -o-transform: translate(22rem, 22.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(4) {
    transform: translate(6rem, 24.5rem);
    -webkit-transform: translate(6rem, 24.5rem);
    -moz-transform: translate(6rem, 24.5rem);
    -ms-transform: translate(6rem, 24.5rem);
    -o-transform: translate(6rem, 24.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(5) {
    transform: translate(-1rem, 9rem);
    -webkit-transform: translate(-1rem, 9rem);
    -moz-transform: translate(-1rem, 9rem);
    -ms-transform: translate(-1rem, 9rem);
    -o-transform: translate(-1rem, 9rem);
  }

  .hrprocess .col-lg-4.mb-5 {
    margin-bottom: 1.5rem !important;
  }

  .hrprocess .info-card {
    padding: 0px 5px;
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .feture-hero .hero-title {
    text-align: center;
  }

  .feture-hero .hero-title .btn {
    width: 100% !important;
  }

  .circle {
    height: calc(649px - 0rem);
  }

  .circle .circle-part {
    max-width: 500px;
    max-height: 500px;
  }

  .circle .circle-center-img-div {
    max-width: 130px;
    max-height: 130px;
  }

  .circle .hw-inner {
    max-width: 330px;
    max-height: 330px;
  }

  .circle .hw-inner .hw-inner-item {
    width: 50px;
    height: 50px;
  }

  .circle .hw-inner .hw-inner-item:nth-child(1) {
    transform: translate(16rem, 2rem);
    -webkit-transform: translate(16rem, 2rem);
    -moz-transform: translate(16rem, 2rem);
    -ms-transform: translate(16rem, 2rem);
    -o-transform: translate(16rem, 2rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(2) {
    transform: translate(15rem, 16rem);
    -webkit-transform: translate(15rem, 16rem);
    -moz-transform: translate(15rem, 16rem);
    -ms-transform: translate(15rem, 16rem);
    -o-transform: translate(15rem, 16rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(3) {
    transform: translate(1rem, 15rem);
    -webkit-transform: translate(1rem, 15rem);
    -moz-transform: translate(1rem, 15rem);
    -ms-transform: translate(1rem, 15rem);
    -o-transform: translate(1rem, 15rem);
  }

  .circle .hw-outer {
    max-width: 450px;
    max-height: 450px;
  }

  .circle .hw-outer .hw-outer-item {
    width: 50px;
    height: 50px;
    padding: 5px;
  }

  .circle .hw-outer .hw-outer-item .reverse-animate-rotate-img {
    /* width: calc(100% - 10px);
    height: calc(100% - 10px); */
    object-fit: cover;
  }

  .circle .hw-outer .hw-outer-item:nth-child(1) {
    transform: translate(9rem, -1.5rem);
    -webkit-transform: translate(9rem, -1.5rem);
    -moz-transform: translate(9rem, -1.5rem);
    -ms-transform: translate(9rem, -1.5rem);
    -o-transform: translate(9rem, -1.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(2) {
    transform: translate(25.5rem, 7rem);
    -webkit-transform: translate(25.5rem, 7rem);
    -moz-transform: translate(25.5rem, 7rem);
    -ms-transform: translate(25.5rem, 7rem);
    -o-transform: translate(25.5rem, 7rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(3) {
    transform: translate(22rem, 22.5rem);
    -webkit-transform: translate(22rem, 22.5rem);
    -moz-transform: translate(22rem, 22.5rem);
    -ms-transform: translate(22rem, 22.5rem);
    -o-transform: translate(22rem, 22.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(4) {
    transform: translate(6rem, 24.5rem);
    -webkit-transform: translate(6rem, 24.5rem);
    -moz-transform: translate(6rem, 24.5rem);
    -ms-transform: translate(6rem, 24.5rem);
    -o-transform: translate(6rem, 24.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(5) {
    transform: translate(-1rem, 9rem);
    -webkit-transform: translate(-1rem, 9rem);
    -moz-transform: translate(-1rem, 9rem);
    -ms-transform: translate(-1rem, 9rem);
    -o-transform: translate(-1rem, 9rem);
  }

  .hrprocess {
    padding: 24px 0;
  }

  .hrprocess .col-lg-4.mb-5 {
    margin-bottom: 1.5rem !important;
  }

  .hrprocess .info-card {
    padding: 0px 5px;
    width: 100%;
    padding-left: 0;
    margin-left: 0px !important;
  }

  .payroll-hero {
    padding-top: 40px;
  }

  .payroll-hero .fullwidth-heading h2 {
    font-size: 32px;
    line-height: 38px;
  }

  .payroll-feature {
    padding: 40px 0;
  }

  .payroll-feature .btn-group {
    display: none !important;
    margin-top: 0 !important;
  }

  .payroll-feature .fullwidth-heading h2 {
    font-size: 16px;
    line-height: 20px;
  }

  .payroll-works {
    padding: 40px 0;
  }

  .payroll-works .btn-group {
    display: none !important;
    margin-top: 0 !important;
  }

  .payroll-works .tab-section {
    padding: 20px 16px;
  }

  .payroll-works .tab-section .nav-tabs {
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .payroll-works .tab-section .nav-tabs::-webkit-scrollbar {
    height: 0;
  }

  .payroll-works .tab-section .nav-tabs .nav-item {
    width: 100%;
    white-space: nowrap;
    display: contents;
  }

  .payroll-works .tab-section .tab-content h2 {
    font-size: 24px;
    line-height: 29px;
    text-align: center !important;
  }

  .payroll-works .tab-section .tab-content p {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .payroll-manage {
    padding: 40px 0;
  }

  .payroll-manage .btn-group {
    display: none !important;
    margin-top: 0 !important;
  }

  .payroll-revolutionize {
    padding: 40px 0;
  }

  .payroll-revolutionize .hero-title .title {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  .payroll-revolutionize .hero-title .desc {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .payroll-revolutionize .hero-title .btn {
    width: 100% !important;
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 20px;
  }

  .payroll-benifits {
    padding: 40px 0;
  }

  .payroll-benifits .fullwidth-heading {
    margin: 0 auto;
  }

  .payroll-action {
    padding: 40px 0;
  }

  .payroll-action .fullwidth-heading .title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
  }

  .payroll-action .fullwidth-heading .desc {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .shiftManagement-hero {
    padding: 40px 0;
  }

  .shiftManagement-hero .hero-title {
    text-align: center;
  }

  .shiftManagement-hero .hero-title .btn {
    width: 100% !important;
    margin: 40px 0;
  }

  .shiftManagement-reduce {
    padding: 40px 0;
    background: #fff;
  }

  .shiftManagement-reduce .schedule-card {
    position: relative;
    margin-bottom: 24px;
  }

  .shiftManagement-reduce::after {
    background: none;
  }

  .shiftManagement-reduce .img img {
    box-shadow: 0px 0px 0.693221px rgba(0, 0, 0, 0.24),
      -6.93221px 6.93221px 13.8644px -1.38644px rgba(174, 174, 174, 0.24);
    border-radius: 4.15933px;
  }

  .shiftManagement-analytics {
    padding: 40px 0;
  }

  .shiftManagement-analytics .fullwidth-heading .title {
    font-size: 24px;
    line-height: 34px;
  }

  .customer-solution {
    padding: 40px 0;
  }

  .shiftManagement-employee-focused .fullwidth-heading {
    margin: 0 auto;
  }

  .shiftManagement-blockchain {
    padding: 40px 0;
  }

  .shiftManagement-blockchain .hero-title h2 {
    font-size: 24px;
    line-height: 33px;
  }

  .shiftManagement-blockchain .hero-title h2 p {
    font-size: 14px;
    line-height: 20px;
  }

  .shiftManagement-schedule {
    padding: 40px 0;
  }

  .shiftManagement-schedule .hero-title h2 {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  .shiftManagement-schedule .hero-title .desc {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }

  .payroll-frequently-question {
    padding: 40px 0;
  }

  .AttendanceManagement-hero .hero-title {
    text-align: center;
  }

  .AttendanceManagement-hero .hero-title .btn {
    text-align: center;
    width: 100% !important;
    margin: 64px 0 37px;
  }

  .AttendanceManagement-employee {
    padding: 40px 0;
  }

  .AttendanceManagement-employee h2 {
    font-size: 24px;
    line-height: 36px;
  }

  .AttendanceManagement-employee p {
    font-size: 16px;
    line-height: 22px;
  }

  .AttendanceManagement-employee .btn {
    margin: 24px 0 40px;
  }

  .AttendanceManagement-biometric .fullwidth-heading .title {
    font-size: 24px;
    line-height: 34px;
  }

  .AttendanceManagement-employeeabsent {
    padding: 40px 0;
  }

  .AttendanceManagement-employeeabsent .heading h2 {
    font-size: 34px;
    line-height: 140%;
  }

  .AttendanceManagement-task {
    padding: 40px 0;
  }

  .AttendanceManagement-task h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .AttendanceManagement-task p {
    font-size: 16px;
    line-height: 24px;
  }

  .AttendanceManagement-task .btn {
    margin: 24px auto 40px;
  }

  .AttendanceManagement-timeTracking {
    padding: 40px 0;
  }

  .AttendanceManagement-timeTracking .fullwidth-heading .section-title {
    text-align: center;
  }

  .AttendanceManagement-timeTracking .fullwidth-heading .title {
    text-align: center;
    font-size: 24px;
    line-height: 33px;
  }

  .AttendanceManagement-timeTracking .fullwidth-heading .btn-group {
    margin-top: 0;
  }

  .AttendanceManagement-timeTracking .time-tracking-part .time-tracking-item {
    text-align: center;
  }

  .AttendanceManagement-blockchain {
    padding: 40px 0;
  }

  .AttendanceManagement-blockchain .row {
    flex-direction: column-reverse !important;
  }

  .AttendanceManagement-blockchain .text-content h2 {
    text-align: center;
  }

  .AttendanceManagement-blockchain .text-content p {
    text-align: center;
    line-height: 20px;
  }

  .AttendanceManagement-blockchain .text-content .btn {
    width: 100%;
    margin: 48px 0 40px;
  }

  .TaxManagement-hero {
    padding: 40px 0;
  }

  .TaxManagement-hero .heading .section-title {
    font-size: 14px;
    line-height: 20px;
  }

  .TaxManagement-hero .heading .title {
    font-size: 32px;
    line-height: 38px;
  }

  .TaxManagement-hero .heading .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .TaxManagement-feature .fullwidth-heading .title {
    font-size: 24px;
    line-height: 29px;
  }

  .TaxManagement-feature .fullwidth-heading .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .TaxManagement-feature .col-lg-12:last-child .card .row {
    flex-direction: column-reverse;
  }

  .TaxManagement-tax .fullwidth-heading .title {
    font-size: 32px;
    line-height: 38px;
  }

  .TaxManagement-tax .tab-section .tab-title {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  .TaxManagement-tax .tab-section .nav-tabs {
    gap: 10px;
    white-space: nowrap;
  }

  .TaxManagement-tax .tab-section .nav-tabs .nav-link {
    width: fit-content !important;
  }

  .TaxManagement-blockchain .fullwidth-heading .title {
    text-align: center;
    font-size: 24px;
    line-height: 33px;
  }

  .TaxManagement-blockchain .fullwidth-heading .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .TaxManagement-blockchain .fullwidth-heading .btn-group>div {
    width: 100%;
  }

  .TaxManagement-blockchain .fullwidth-heading .btn-group .btn {
    max-width: 100%;
    width: 100%;
    margin: 40px 0;
  }

  .notification-coreHR {
    padding: 40px 0;
  }

  .TaxManagement-taxCalculation .row {
    flex-direction: column-reverse;
  }

  .TaxManagement-taxCalculation .heading h2 {
    text-align: center;
  }

  .TaxManagement-taxCalculation .heading p {
    text-align: center;
    font-size: 14px !important;
  }

  .TaxManagement-taxCalculation ul li {
    font-size: 16px;
  }

  .TaxManagement-salaryCalculation .row {
    flex-direction: column;
  }

  .TaxManagement-taxRules .row {
    flex-direction: column;
  }

  .leaveManagement-hero {
    padding-top: 40px;
  }

  .leaveManagement-hero .fullwidth-heading h2 {
    font-size: 32px;
    line-height: 38px;
  }

  .leaveManagement-hero .fullwidth-heading .btn-group {
    margin: 0px 0 !important;
  }

  .leaveManagement-hero .fullwidth-heading .btn-group>div>.btn-group {
    margin: 40px 0 !important;
    flex-direction: column;
  }

  .leaveManagement-hero .fullwidth-heading .btn-group>div {
    width: 100%;
  }

  .leaveManagement-hero .fullwidth-heading .btn-group>div>.btn-group .btn {
    width: 100%;
    max-width: 100%;
  }

  .leaveManagement-system h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .leaveManagement-system .col-lg-3 {
    margin-bottom: 1rem;
  }

  .leaveManagement-control h2 {
    font-size: 24px;
    line-height: 33px;
  }

  .leaveManagement-takeContol .fullwidth-heading .title {
    text-align: center;
  }

  .leaveManagement-takeContol .fullwidth-heading .details {
    text-align: center;
  }

  .leaveManagement-takeContol .fullwidth-heading .btn {
    width: 100%;
    margin: 24px 0 40px;
  }

  .leaveManagement-Workweeks .row {
    flex-direction: column-reverse;
  }

  .leaveManagement-Workweeks .fullwidth-heading .title {
    text-align: center;
  }

  .leaveManagement-Workweeks .fullwidth-heading .desc {
    text-align: center;
  }

  .leaveManagement-Workweeks .fullwidth-heading>div .btn {
    width: 100%;
    margin: 24px 0 40px;
  }

  .leaveManagement-blockchain {
    padding: 40px 0;
  }

  .leaveManagement-blockchain .heading h2 {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  .leaveManagement-blockchain .heading p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .leaveManagement-blockchain .heading .hr-list-item .btn {
    width: 100%;
    margin: 24px 0 40px;
  }

  .leaveManagement-teamCalender {
    padding: 40px 0;
  }

  .leaveManagement-teamCalender .heading h2 {
    text-align: center;
  }

  .leaveManagement-teamCalender .heading p {
    text-align: center;
  }

  .leaveManagement-teamCalender .heading .hr-list-item .btn {
    width: 100%;
    margin: 24px 0 40px;
  }

  .leaveManagement-teamCalender .col-lg-6:last-child .heading {
    padding-top: 64px;
  }

  .leaveManagement-teamCalender .col-lg-6:last-child .section-desc {
    margin-bottom: 0;
  }

  .employeeMonitoring-hero {
    padding-top: 40px;
  }

  .employeeMonitoring-hero .fullwidth-heading .section-title {
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 20px;
  }

  .employeeMonitoring-hero .fullwidth-heading .title {
    font-size: 32px;
    line-height: 38px;
  }

  .employeeMonitoring-hero .fullwidth-heading .desc {
    font-size: 20px;
    line-height: 28px;
  }

  .EmployeeMonitoring-integrate .fullwidth-heading .desc {
    font-size: 16px;
  }

  .blockSecurity-EmployeeMonitoring .heading h2 {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  .blockSecurity-EmployeeMonitoring .heading p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .employee .hr-list-item ul li::before {
    min-width: 20px;
  }

  .blockSecurity-ScheduleTracking .heading h2 {
    text-align: center;
  }

  .blockSecurity-ScheduleTracking .heading p {
    text-align: center;
  }

  .blockSecurity-TrackingReport .heading h2 {
    text-align: center;
  }

  .blockSecurity-TrackingReport .heading p {
    text-align: center;
  }

  .TaxManagement-blockchain .fullwidth-heading>div {
    width: 100%;
  }

  .TaxManagement-blockchain .fullwidth-heading .btn {
    width: 100%;
    max-width: unset;
    margin: 40px 0;
  }

  .growing-part .hero-title h2 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }

  .growing-part .hero-title-animate {
    flex-direction: column !important;
  }

  .NoticeBoard-management .img-content {
    max-width: 120px;
    max-height: 120px;
    margin: 0 auto;
    margin-bottom: 40px !important;
  }

  .notification-blockchain {
    padding: 40px 0;
  }

  .NoticeBoard-blockchain .fullwidth-heading .title {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  .NoticeBoard-blockchain .fullwidth-heading .desc {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .NoticeBoard-blockchain .fullwidth-heading>div a {
    width: 100%;
    margin: 40px 0;
  }

  .NoticeBoard-friendlyInterface {
    padding-top: 40px;
  }

  .NoticeBoard-friendlyInterface .hero-title h2 {
    font-size: 24px !important;
    line-height: 34px;
  }

  .NoticeBoard-friendlyInterface .hero-title .hr-list-item ul li {
    text-align: left;
  }

  .NoticeBoard-attachfile {
    background: #f7f7f9;
    padding: 40px 0;
  }

  .NoticeBoard-attachfile .row {
    flex-direction: column-reverse;
  }

  .NoticeBoard-attachfile .fullwidth-heading h2 {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  .NoticeBoard-attachfile .fullwidth-heading p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .blockSecurity-hero {
    padding: 40px 0;
    background-position: right;
  }

  .blockSecurity-decentraliazation .fullwidth-heading {
    padding-top: 42px;
  }

  .blockSecurity-decentraliazation .fullwidth-heading .section-title {
    text-align: center;
  }

  .blockSecurity-decentraliazation .fullwidth-heading .title {
    text-align: center;
    font-size: 24px;
  }

  .blockSecurity-decentraliazation .fullwidth-heading .desc {
    text-align: center;
    font-size: 14px;
  }

  .blockSecurity-decentraliazation .fullwidth-heading .btn-group {
    margin-top: 0 !important;
  }

  .blockSecurity-secureIdentity {
    padding: 40px 0;
  }

  .blockSecurity-secureIdentity .fullwidth-heading .title {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }

  .blockSecurity-secureIdentity .fullwidth-heading .desc {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }

  .blockSecurity-dataProtection .row {
    flex-direction: column-reverse;
  }

  .blockSecurity-decentraliazation {
    padding: 40px 0;
  }

  .blockSecurity-decentraliazation .row {
    flex-direction: column-reverse;
  }

  .domaincustomization-feature .fullwidth-heading .title {
    font-size: 24px;
    line-height: 29px;
  }

  .domaincustomization-feature .fullwidth-heading .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .domaincustomization-reliableServices .hero-title h2 {
    font-size: 24px !important;
    line-height: 34px;
  }

  .domaincustomization-reliableServices .hero-title .hr-list-item ul li {
    text-align: left;
  }

  .domaincustomization-reliableServices .hero-title-animate .hero-title .btn {
    margin-top: 12px !important;
  }

  .domaincustomization-PersonalConsultant .row {
    justify-content: center;
  }

  .domaincustomization-blockchain .fullwidth-heading .title {
    text-align: center;
    font-size: 24px;
    line-height: 33px;
  }

  .domaincustomization-blockchain .fullwidth-heading .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .domaincustomization-blockchain .fullwidth-heading>div {
    width: 100%;
  }

  .domaincustomization-blockchain .fullwidth-heading>div .btn {
    width: 100%;
    margin: 40px 0;
  }

  .domaincustomization-security h2 {
    font-size: 24px !important;
    line-height: 34px !important;
  }

  .domaincustomization-security p {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .notification-hero .hero-title h6 {
    text-align: center !important;
    font-size: 14px;
  }

  .notification-hero .hero-title h2 {
    text-align: center !important;
  }

  .notification-hero .hero-title p {
    text-align: center !important;
  }

  .notification-feature .fullwidth-heading .title {
    font-size: 24px;
  }

  .notification-coreHR .heading .section-name {
    text-align: center;
    margin-top: 40px;
  }

  .notification-coreHR .heading h2 {
    text-align: center;
    /* margin-top: 40px; */
  }

  .notification-coreHR .heading .section-desc {
    text-align: center;
    font-size: 14px !important;
  }

  .Notification-online-service {
    display: none;
  }

  .everySituation .fullwidth-heading h2 {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  .everySituation .btn-group {
    margin-top: 0 !important;
  }

  .everySituation .fullwidth-heading>div p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .notification-task .row {
    flex-direction: column-reverse;
  }

  .notification-growing {
    padding: 40px 0;
  }

  .notification-growing .hero-title h2 {
    font-size: 24px !important;
    line-height: 34px;
  }

  .notification-growing .hero-title-animate .hero-title .btn {
    margin-top: 12px !important;
  }

  .notification-blockchain .fullwidth-heading .title {
    text-align: center;
    font-size: 24px;
    line-height: 33px;
  }

  .notification-blockchain .fullwidth-heading .desc {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }

  .notification-blockchain .fullwidth-heading .btn-group {
    margin-top: 0 !important;
  }

  .notification-blockchain .fullwidth-heading>div .btn {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .circle {
    height: calc(352px - 0rem);
  }

  .circle .circle-part {
    max-width: 300px;
    max-height: 300px;
  }

  .circle .circle-center-img-div {
    max-width: 69px;
    max-height: 69px;
    box-shadow: 0px 0px 0px 10px rgba(1, 63, 215, 0.08);
  }

  .circle .circle-center-img-div img {
    max-width: 65px;
    max-height: 65px;
    object-fit: scale-down;
    padding: 5px;
  }

  .circle .animate-rotate-img {
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    object-fit: cover;
  }

  .circle .hw-inner {
    max-width: 176px;
    max-height: 176px;
  }

  .circle .hw-inner .hw-inner-item {
    width: 28px;
    height: 28px;
  }

  .circle .hw-inner .hw-inner-item:nth-child(1) {
    transform: translate(8rem, 0rem);
    -webkit-transform: translate(8rem, 0rem);
    -moz-transform: translate(8rem, 0rem);
    -ms-transform: translate(8rem, 0rem);
    -o-transform: translate(8rem, 0rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(2) {
    transform: translate(9rem, 7.5rem);
    -webkit-transform: translate(9rem, 7.5rem);
    -moz-transform: translate(9rem, 7.5rem);
    -ms-transform: translate(9rem, 7.5rem);
    -o-transform: translate(9rem, 7.5rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(3) {
    transform: translate(1rem, 8.5rem);
    -webkit-transform: translate(1rem, 8.5rem);
    -moz-transform: translate(1rem, 8.5rem);
    -ms-transform: translate(1rem, 8.5rem);
    -o-transform: translate(1rem, 8.5rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(4) {
    transform: translate(0rem, 1.5rem);
    -webkit-transform: translate(0rem, 1.5rem);
    -moz-transform: translate(0rem, 1.5rem);
    -ms-transform: translate(0rem, 1.5rem);
    -o-transform: translate(0rem, 1.5rem);
  }

  .circle .hw-outer {
    max-width: 267px;
    max-height: 267px;
  }

  .circle .hw-outer .hw-outer-item {
    width: 28px;
    height: 28px;
  }

  .circle .hw-outer .hw-outer-item .reverse-animate-rotate-img {
    /* width: calc(100% - 0px);
    height: calc(100% - 0px); */
    object-fit: cover;
  }

  .circle .hw-outer .hw-outer-item:nth-child(1) {
    transform: translate(8rem, -1rem);
    -webkit-transform: translate(8rem, -1rem);
    -moz-transform: translate(8rem, -1rem);
    -ms-transform: translate(8rem, -1rem);
    -o-transform: translate(8rem, -1rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(2) {
    transform: translate(15.5rem, 7rem);
    -webkit-transform: translate(15.5rem, 7rem);
    -moz-transform: translate(15.5rem, 7rem);
    -ms-transform: translate(15.5rem, 7rem);
    -o-transform: translate(15.5rem, 7rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(3) {
    transform: translate(11rem, 14.5rem);
    -webkit-transform: translate(11rem, 14.5rem);
    -moz-transform: translate(11rem, 14.5rem);
    -ms-transform: translate(11rem, 14.5rem);
    -o-transform: translate(11rem, 14.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(4) {
    transform: translate(3rem, 14.5rem);
    -webkit-transform: translate(3rem, 14.5rem);
    -moz-transform: translate(3rem, 14.5rem);
    -ms-transform: translate(3rem, 14.5rem);
    -o-transform: translate(3rem, 14.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(5) {
    transform: translate(-1rem, 7rem);
    -webkit-transform: translate(-1rem, 7rem);
    -moz-transform: translate(-1rem, 7rem);
    -ms-transform: translate(-1rem, 7rem);
    -o-transform: translate(-1rem, 7rem);
  }

  .future .info-card {
    min-height: 124px;
    max-height: 124px;
    padding: 16px 5px;
  }

  .hrprocess .info-card .card-logo {
    background: #ffffff;
    border: none;
    box-shadow: none;
    border-radius: 13.8489px;
    padding: 0;
    margin-bottom: 8px;
    max-width: 40px;
  }

  .hrprocess .info-card p {
    display: none;
  }

  .hrprocess .info-card .learnMoreBtn {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .circle {
    height: calc(352px - 0rem);
  }

  .circle .circle-part {
    max-width: 300px;
    max-height: 300px;
  }

  .circle .circle-center-img-div {
    /* max-width: 69px;
    max-height: 69px; */
    box-shadow: 0px 0px 0px 10px rgba(1, 63, 215, 0.08);
  }

  .circle .circle-center-img-div img {
    /* max-width: 65px;
    max-height: 65px; */
    object-fit: scale-down;
    /* padding: 5px; */
  }

  .circle .animate-rotate-img {
    /* width: calc(100% - 12px);
    height: calc(100% - 12px); */
    scale: 0.9;
    object-fit: cover;
  }

  .circle .hw-inner {
    max-width: 176px;
    max-height: 176px;
  }

  .circle .hw-inner .hw-inner-item {
    /* width: 28px;
    height: 28px; */
  }

  .circle .hw-inner .hw-inner-item:nth-child(1) {
    transform: translate(8rem, 0rem);
    -webkit-transform: translate(8rem, 0rem);
    -moz-transform: translate(8rem, 0rem);
    -ms-transform: translate(8rem, 0rem);
    -o-transform: translate(8rem, 0rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(2) {
    transform: translate(9rem, 7.5rem);
    -webkit-transform: translate(9rem, 7.5rem);
    -moz-transform: translate(9rem, 7.5rem);
    -ms-transform: translate(9rem, 7.5rem);
    -o-transform: translate(9rem, 7.5rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(3) {
    transform: translate(1rem, 8.5rem);
    -webkit-transform: translate(1rem, 8.5rem);
    -moz-transform: translate(1rem, 8.5rem);
    -ms-transform: translate(1rem, 8.5rem);
    -o-transform: translate(1rem, 8.5rem);
  }

  .circle .hw-inner .hw-inner-item:nth-child(4) {
    transform: translate(0rem, 1.5rem);
    -webkit-transform: translate(0rem, 1.5rem);
    -moz-transform: translate(0rem, 1.5rem);
    -ms-transform: translate(0rem, 1.5rem);
    -o-transform: translate(0rem, 1.5rem);
  }

  .circle .hw-outer {
    max-width: 267px;
    max-height: 267px;
  }

  .circle .hw-outer .hw-outer-item {
    /* width: 28px;
    height: 28px; */
  }

  .circle .hw-outer .hw-outer-item .reverse-animate-rotate-img {
    /* width: calc(100% - 0px);
    height: calc(100% - 0px); */
    scale: 0.8;
    object-fit: cover;
  }

  .circle .hw-outer .hw-outer-item:nth-child(1) {
    transform: translate(8rem, -1rem);
    -webkit-transform: translate(8rem, -1rem);
    -moz-transform: translate(8rem, -1rem);
    -ms-transform: translate(8rem, -1rem);
    -o-transform: translate(8rem, -1rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(2) {
    transform: translate(15.5rem, 7rem);
    -webkit-transform: translate(15.5rem, 7rem);
    -moz-transform: translate(15.5rem, 7rem);
    -ms-transform: translate(15.5rem, 7rem);
    -o-transform: translate(15.5rem, 7rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(3) {
    transform: translate(11rem, 14.5rem);
    -webkit-transform: translate(11rem, 14.5rem);
    -moz-transform: translate(11rem, 14.5rem);
    -ms-transform: translate(11rem, 14.5rem);
    -o-transform: translate(11rem, 14.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(4) {
    transform: translate(3rem, 14.5rem);
    -webkit-transform: translate(3rem, 14.5rem);
    -moz-transform: translate(3rem, 14.5rem);
    -ms-transform: translate(3rem, 14.5rem);
    -o-transform: translate(3rem, 14.5rem);
  }

  .circle .hw-outer .hw-outer-item:nth-child(5) {
    transform: translate(-1rem, 7rem);
    -webkit-transform: translate(-1rem, 7rem);
    -moz-transform: translate(-1rem, 7rem);
    -ms-transform: translate(-1rem, 7rem);
    -o-transform: translate(-1rem, 7rem);
  }
}