/* hero-section */
.hero.hero-section .hero-animate {
  overflow: hidden;
}

.hero-section .logo-slider {
  max-width: 896px;
  height: 850px;
  position: relative;
}

.hero-section .logo-slider .slider-bg-img img {
  width: 100%;
  height: 100%;
}

.hero-section .logo-slider .marquee-wrap {
  position: absolute;
  top: 0;
  right: -5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.hero-section .logo-slider .marquee-block {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  transform: rotate(-16deg);
  -webkit-transform: rotate(-16deg);
  -moz-transform: rotate(-16deg);
  -ms-transform: rotate(-16deg);
  -o-transform: rotate(-16deg);
}

.hero-section .logo-slider .marquee-inner {
  position: absolute;
}

.hero-section .logo-slider .marquee-inner.to-left {
  animation: marqueeTop 50s linear infinite;
  -webkit-animation: marqueeTop 50s linear infinite;
}

.hero-section .logo-slider .marquee-inner.to-right {
  animation: marqueeBottom 50s linear infinite;
  -webkit-animation: marqueeBottom 50s linear infinite;
}

/* .hero-section .logo-slider .marquee-item {
  width: 100%;
  margin: 20px 0px;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
} */
.hero-section .logo-slider .marquee-item {
  max-width: 200px;
  width: 100%;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding: 40px 24px;
  border-radius: 16px;
  border: 1px solid rgba(145, 158, 171, 0.24);
  background: var(--background-light-paper, #fff);
  box-shadow: 0px 24px 48px 0px rgba(145, 158, 171, 0.16);
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  /* white-space: nowrap; */
}

.hero-section .logo-slider .marquee-item .img img {
  width: 44.8px;
  height: 44.8px;
  margin: 17.6px;
  object-fit: scale-down;
}

.hero-section
  .logo-slider
  .marquee-wrap
  .marquee-block:nth-child(1)
  .marquee-item
  .img
  img {
  filter: invert(30%) sepia(10%) saturate(500%) contrast(150%)
    hue-rotate(360deg);
}

.hero-section
  .logo-slider
  .marquee-wrap
  .marquee-block:nth-child(2)
  .marquee-item
  .img
  img {
  filter: invert(30%) sepia(10%) saturate(500%) contrast(150%)
    hue-rotate(203deg);
}

.hero-section
  .logo-slider
  .marquee-wrap
  .marquee-block:nth-child(3)
  .marquee-item
  .img
  img {
  filter: saturate(200%) invert(20%) sepia(90%) saturate(5000%)
    hue-rotate(350deg) contrast(103%);
}

.hero-section .logo-slider .marquee-item .content p {
  margin-bottom: 0;
  color: #212b36;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

@keyframes marqueeTop {
  0% {
    top: 0;
  }

  100% {
    top: -100%;
  }
}

@keyframes marqueeBottom {
  0% {
    top: -100%;
  }

  100% {
    top: 0;
  }
}

/* hero-section */
/* overview-section */
.overview-section {
  padding: 121px 0;
}

.overview-section .heading {
  margin-bottom: 60px;
}

.overview-section .card-info-wrapper:nth-child(2) {
  margin-top: 40px;
}

.overview-section .card-info-wrapper:nth-child(3) {
  margin-top: -40px;
}

.overview-section .info-card h3 a {
  color: #243757;
}

/* overview-section */

/* keyfeature */
.keyfeature {
  padding: 114px 0;
}

.keyfeature .section-name {
  text-align: center;
}

.keyfeature .animate-key {
  position: relative;
  /* margin-top: 100px; */
  margin: 100px auto 0;
  max-width: 1116px;
  max-height: 1127px;
  min-width: 1116px;
  min-height: 1127px;
}

.keyfeature .animate-key svg {
  width: calc(100% - 0%);
  margin: auto;
  display: flex;
}

.keyfeature .animate-key-card {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.keyfeature .animate-key-card .info-card {
  position: absolute;
  min-height: 270px;
  min-width: 250px;
  max-height: 270px;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.keyfeature .animate-key-card .info-card .loading-spin {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.keyfeature .animate-key-card .info-card .img-content img {
  max-width: 60px;
  max-height: 60px;
}

.info-card .card-logo {
  background: #ffffff;
  border: 0.865554px solid rgba(145, 158, 171, 0.24);
  box-shadow: 0px 20.7733px 41.5466px rgba(145, 158, 171, 0.16);
  border-radius: 13.8489px;
  padding: 21.94px;
  margin-bottom: 24px;
  max-width: 90px;
}

.keyfeature .animate-key-card .info-card:nth-child(1) {
  top: -4.5%;
  left: 16%;
}

.keyfeature .animate-key-card .info-card:nth-child(2) {
  top: -4.5%;
  left: 40%;
}

.keyfeature .animate-key-card .info-card:nth-child(3) {
  top: -4.5%;
  left: 64%;
}

.keyfeature .animate-key-card .info-card:nth-child(4) {
  top: 25%;
  left: 0%;
}

.keyfeature .animate-key-card .info-card:nth-child(5) {
  top: 50%;
  left: 0%;
}

.keyfeature .animate-key-card .info-card:nth-child(6) {
  top: 25%;
  left: 78%;
}

.keyfeature .animate-key-card .info-card:nth-child(7) {
  top: 50%;
  left: 78%;
}

.keyfeature .animate-key-card .info-card:nth-child(8) {
  top: 78%;
  left: 16%;
}

.keyfeature .animate-key-card .info-card:nth-child(9) {
  top: 78%;
  left: 39.5%;
}

.keyfeature .animate-key-card .info-card:nth-child(10) {
  top: 78%;
  left: 63%;
}

.keyfeature .animate-key-card .info-card .text-content h3 a {
  color: #212b36 !important;
}

/* keyfeature */

/* Howtowork */
.Howtowork {
  padding: 242px 0 129px;
  background: #243757;
}

.Howtowork .works-part {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  overflow-x: hidden;
}

.Howtowork .works-part .works-slider {
  max-width: 1000px;
  width: 100%;
}

.Howtowork .works-part .works-slider .swiper {
  padding: 56px 0;
}

.Howtowork .works-part .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Howtowork .works-part .swiper-slide .section-name {
  color: #6894ff !important;
}

.Howtowork .works-part .swiper-scrollbar {
  width: calc(100% - 10rem);
  bottom: 3rem;
  background: #8596ad;
}

.Howtowork .works-part .swiper-scrollbar .swiper-scrollbar-drag {
  background: #fff;
}

.Howtowork .works-part .slider-pagination-btn {
  position: relative;
  bottom: -2rem;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 24px;
}

.Howtowork .works-part .slider-pagination-btn button {
  background-color: transparent;
  box-shadow: none;
  border: 0px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.Howtowork .works-part .slider-pagination-btn button.swiper-button-disabled {
  cursor: no-drop;
}

.Howtowork .works-part .works-right {
  /* max-width: 451px;
  padding-left: 24px; */
  max-width: calc(100% - 6%);
  padding-left: 24px;
}

.Howtowork .works-part .works-right ul {
  margin-top: 32px;
}

.Howtowork .works-part .works-right ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding-left: 1rem;
  margin-bottom: 12px;
  position: relative;
}

.Howtowork .works-part .works-right ul li::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0%;
  background: #fff;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

/* Howtowork */

/* WhySmartOffice */
.WhySmartOffice {
  padding: 107px 0;
}

.WhySmartOffice .hr-tab .heading {
  max-width: 451px;
}

.WhySmartOffice .hr-tab h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-align: left !important;
  color: #243757;
}

.WhySmartOffice .hr-tab .section-desc {
  text-align: left !important;
  margin-bottom: 24px;
}

.WhySmartOffice .hr-tab .hr-list-item ul li {
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 34px;
}

/* WhySmartOffice */

/* racipeLibrary */
.racipeLibrary {
  padding: 74px 0;
}

.racipeLibrary .marquee-block {
  width: 17%;
  height: 800px;
  /* overflow: hidden; */
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px;
  padding: 30px 0;
  float: left;
}

.racipeLibrary .marquee-inner {
  display: block;
  width: 100%;
  position: absolute;
  margin: 20px 0;
  border-left: 2px dashed rgba(36, 55, 87, 0.12);
  padding-left: 40px;
}

.racipeLibrary .recipe-library {
  margin-top: 72px;
  overflow: hidden;
}

.racipeLibrary .recipe-library > .img .marquee-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.racipeLibrary .marquee-inner.to-left {
  animation: marqueeTop 50s linear infinite;
}

.racipeLibrary .marquee-inner.to-right {
  animation: marqueeBottom 50s linear infinite;
  -webkit-animation: marqueeBottom 50s linear infinite;
}

.racipeLibrary .marquee-item {
  width: 100%;
  transition: all 0.2s ease-out;
  position: relative;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 12px 30px 0px rgba(145, 158, 171, 0.16);
  padding: 24px;
  margin-bottom: 100px;
}
.racipeLibrary .marquee-item:last-child {
  margin-bottom: 0;
}
.racipeLibrary .marquee-item::after {
  content: "";
  position: absolute;
  top: -1.3rem;
  left: -3.4rem;
  width: 25px;
  height: 25px;
  background: #013fd7;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.racipeLibrary .marquee-item .icon {
  position: absolute;
  top: -2rem;
  left: -1rem;
  width: 48px;
  height: 48px;
  border-radius: 7px;
  border: 1px solid #99b6ff;
  background: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.racipeLibrary .marquee-item h2 {
  color: #243757;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 21.6px */
}
.racipeLibrary .marquee-item p {
  color: #243757;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.racipeLibrary .marquee-item span {
  color: #664900;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 185.714% */
  padding: 2px 8px;
  border-radius: 6px;
  background: #ffe299;
}
@keyframes marqueeTop {
  0% {
    top: 0;
  }

  100% {
    top: -100%;
  }
}

@keyframes marqueeBottom {
  0% {
    top: -100%;
  }

  100% {
    top: 0;
  }
}

/* racipeLibrary */

/* core-hr */
.core-hr {
  padding: 99px 0;
}

.core-hr .card-info-wrapper:nth-child(2) {
  margin-top: 40px;
}

.core-hr .card-info-wrapper:nth-child(3) {
  margin-top: -40px;
}

.core-hr .heading {
  max-width: 451px;
  margin-left: 48px;
}

.core-hr .centered-heading h2 {
  margin: auto auto 0 0;
  text-align: left;
}

.core-hr .section-desc {
  font-family: "Manrope";
  color: #243757;
  font-style: normal;
  font-weight: 400;
  max-width: 450px;
  width: 100%;
  margin: auto auto 0 0;
  text-align: left;
}

.core-hr .hr-list-item {
  margin-top: 33px;
}

.core-hr .hr-list-item li {
  font-weight: 500;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #243757;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 34px;
}

/* core-hr */

/* blockChain */
.blockChain {
  background: #243757;
}

.blockChain h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  color: #6894ff;
}

.blockChain h2 {
  font-weight: 800;
  font-size: 48px;
  line-height: 62px;
  letter-spacing: -2px;
  color: #ffffff;
}

.blockChain p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
}

.blockChain .img img {
  filter: invert(18%) sepia(75%) saturate(378%) hue-rotate(176deg)
    brightness(93%) contrast(95%);
}

/* blockChain */
/* OptimizeTime */
.text-content a {
  color: #243757;
}

/* OptimizeTime */

/* StartWithUs */
.StartWithUs {
  padding: 129px 0 118px;
}

/* StartWithUs */

/* blog-section */
.blog-section {
  padding: 64px 0;
}

.blog-section .view-all {
  width: auto;
}

.blog-card .thumb_nail_img {
  max-height: 240px;
  min-height: 240px;
  height: 100%;
  object-fit: scale-down;
}

.blog-section .text-content {
  padding: 32px 16px !important;
  background-color: #f5f6f8;
}

/* blog-section */

/* integrate */
.integrate {
  padding: 152px 0;
}

.integrate .marquee .child {
  margin: 0 40px;
}

.integrate .marquee .img {
  background: #ffffff;
  border: 0.666107px solid #e2e5f1;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
}

/* integrate */

/* notification center */
.request-demo-modal .PhoneInputInput {
  border: 0px;
}

.request-demo-modal .PhoneInputInput:focus-within {
  outline: none;
}

/* notification center */

/* responsive */
@media (min-width: 2000px) {
  .hero-title-animate {
    justify-content: center !important;
  }
}

@media (min-width: 1200px) and (max-width: 1470px) {
  .Howtowork .works-part .works-slider {
    max-width: 800px;
  }

  .Howtowork .works-right h2 {
    font-size: 35px;
  }

  .Howtowork .works-part .works-right ul li {
    font-size: 16px;
  }

  .blockChain .hero-title {
    padding: 40px 0;
  }

  .blockChain .hero-title h2 {
    font-size: 35px;
    line-height: 50px;
  }

  .blockChain .hero-title p {
    font-size: 14px;
    line-height: 26px;
  }
}

@media (min-width: 1126px) and (max-width: 1199px) {
  .keyfeature .animate-key {
    min-width: 870px;
    min-height: 870px;
  }

  .keyfeature .animate-key svg {
    width: calc(90% - 0%);
    margin: auto;
    display: flex;
  }

  .keyfeature .animate-key-card .info-card {
    min-height: 180px;
    min-width: 200px;
    max-height: 180px;
    max-width: 200px;
  }

  .keyfeature .animate-key-card .info-card .text-content p {
    display: none;
  }

  .keyfeature .animate-key-card .info-card:nth-child(1) {
    top: 11%;
    left: 13%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(2) {
    top: 11%;
    left: 38%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(3) {
    top: 11%;
    left: 63%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(4) {
    left: 0%;
    top: 33%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(5) {
    left: 0%;
    top: 50%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(6) {
    top: 32%;
    left: 79%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(7) {
    top: 50%;
    left: 79%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(8) {
    top: 74%;
    left: 15%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(9) {
    top: 74%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(10) {
    top: 74%;
  }

  .Howtowork .works-part .works-slider {
    max-width: 700px;
  }

  .Howtowork .works-right h2 {
    font-size: 30px;
  }

  .Howtowork .works-part .works-right ul li {
    font-size: 14px;
  }

  .blockChain .hero-title {
    padding: 40px 0;
  }

  .blockChain .hero-title h2 {
    font-size: 35px;
    line-height: 50px;
  }

  .blockChain .hero-title p {
    font-size: 14px;
    line-height: 26px;
  }
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .racipeLibrary .marquee-inner {
    padding-left: 27px;
  }
  .racipeLibrary .marquee-block {
    width: 20%;
    margin: 20px 12px;
  }
  .racipeLibrary .marquee-item {
    padding: 16px 10px 10px;
    margin-bottom: 50px;
  }
  .racipeLibrary .marquee-item h2 {
    font-size: 10px;
  }
  .racipeLibrary .marquee-item p {
    font-size: 9px;
  }
  .racipeLibrary .marquee-item span {
    font-size: 9px;
  }
  .racipeLibrary .marquee-item .icon {
    top: -1rem;
    left: -1rem;
    width: 30px;
    height: 30px;
    padding: 7px;
  }

  .racipeLibrary .marquee-item::after {
    width: 20px;
    height: 20px;
    left: -38px;
    top: -11px;
  }
}
@media (min-width: 1024px) and (max-width: 1126px) {
  .hero-section .hero-title h2 {
    font-size: 45px;
  }

  .hero-section .hero-title p {
    font-size: 16px;
  }

  .hero-section .hero-title .btn {
    margin-top: 1rem !important;
  }

  .keyfeature .animate-key-card .info-card .text-content p {
    display: none;
  }

  .keyfeature .animate-key {
    min-width: 870px;
    min-height: 870px;
  }

  .keyfeature .animate-key svg {
    width: calc(90% - 0%);
    margin: auto;
    display: flex;
  }

  .keyfeature .animate-key-card .info-card {
    min-height: 180px;
    min-width: 200px;
    max-height: 180px;
    max-width: 200px;
  }

  .keyfeature .animate-key-card .info-card .text-content p {
    display: none;
  }

  .keyfeature .animate-key-card .info-card:nth-child(1) {
    top: 11%;
    left: 13%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(2) {
    top: 11%;
    left: 38%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(3) {
    top: 11%;
    left: 63%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(4) {
    left: 0%;
    top: 33%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(5) {
    left: 0%;
    top: 50%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(6) {
    top: 32%;
    left: 79%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(7) {
    top: 50%;
    left: 79%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(8) {
    top: 74%;
    left: 15%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(9) {
    top: 74%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(10) {
    top: 74%;
  }

  .Howtowork .works-part .works-slider {
    max-width: 600px;
  }

  .Howtowork .works-right h2 {
    font-size: 30px;
  }

  .Howtowork .works-part .works-right ul li {
    font-size: 14px;
  }

  .blockChain .hero-title {
    padding: 40px 0;
  }

  .blockChain .hero-title h2 {
    font-size: 30px;
    line-height: 44px;
  }

  .blockChain .hero-title p {
    font-size: 14px;
    line-height: 26px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .keyfeature .animate-key-card .info-card .text-content p {
    display: none;
  }

  .keyfeature .animate-key {
    min-width: 870px;
    min-height: 870px;
  }

  .keyfeature .animate-key svg {
    width: calc(90% - 0%);
    margin: auto;
    display: flex;
  }

  .keyfeature .animate-key-card .info-card {
    min-height: 180px;
    min-width: 200px;
    max-height: 180px;
    max-width: 200px;
  }

  .keyfeature .animate-key-card .info-card .text-content p {
    display: none;
  }

  .keyfeature .animate-key-card .info-card:nth-child(1) {
    top: 11%;
    left: 13%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(2) {
    top: 11%;
    left: 38%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(3) {
    top: 11%;
    left: 63%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(4) {
    left: 0%;
    top: 33%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(5) {
    left: 0%;
    top: 50%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(6) {
    top: 32%;
    left: 79%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(7) {
    top: 50%;
    left: 79%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(8) {
    top: 74%;
    left: 15%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(9) {
    top: 74%;
  }

  .keyfeature .animate-key-card .info-card:nth-child(10) {
    top: 74%;
  }

  .Howtowork .works-part .works-slider {
    max-width: 550px;
  }

  .Howtowork .works-right h2 {
    font-size: 30px;
  }

  .Howtowork .works-part .works-right ul li {
    font-size: 14px;
  }
}

.Howtowork .works-part .col-12:first-child {
  justify-content: flex-end;
  display: flex;
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* home hero responsive start */
  .hero-section .logo-slider .marquee-item {
    margin: 16px 0px;
    gap: 8px;
    padding: 16px;
  }

  .hero-section .logo-slider .marquee-item .img img {
    width: 32px;
    height: 32px;
    margin: 16px;
  }

  .hero-section .logo-slider .marquee-item .content p {
    font-size: 12px;
    line-height: 18px;
  }
  /* home hero responsive end */
}

@media screen and (max-width: 1199px) {
  .hero-section .logo-slider {
    height: 750px;
  }
}

@media screen and (max-width: 700px) {
  .hero-section .logo-slider {
    height: 600px;
  }
}

@media screen and (max-width: 991px) {
  .hero-section .logo-slider {
    height: 700px;
  }

  .integrate .marquee .child {
    margin: 0 20px;
  }

  .hero-section .hero-title-animate {
    gap: 40px;
  }

  .hero-section .hero-title {
    text-align: center;
    top: 0;
    left: 0;
    max-width: max-content;
  }

  .hero-section .hero-title h2 {
    font-size: 26px;
    line-height: 35px;
    letter-spacing: 0px;
  }

  .hero-section .hero-title p {
    font-size: 16px;
    margin-bottom: 0 !important;
  }

  .hero-section .hero-title .btn {
    margin-top: 40px !important;
    width: 100% !important;
  }

  .overview-section .card-info-wrapper:nth-child(2) {
    margin-top: 0px;
  }

  .overview-section .card-info-wrapper:nth-child(3) {
    margin-top: -0px;
  }

  .keyfeature {
    padding: 24px 0;
  }

  .keyfeature .animate-key {
    min-width: auto;
    min-height: auto;
    max-height: unset;
  }

  .keyfeature .animate-key svg {
    display: none;
  }

  .keyfeature .animate-key-card {
    position: unset;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .keyfeature .animate-key-card .info-card {
    position: relative;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    flex: 0 0 auto;
    width: 48%;
    max-width: 100%;
    min-height: auto;
  }

  .keyfeature .animate-key-card .info-card .loading-spin {
    display: none !important;
  }

  .keyfeature .animate-key-card .info-card .text-content p {
    display: none;
  }

  .Howtowork .works-part {
    flex-direction: column-reverse;
  }

  .WhySmartOffice .heading {
    text-align: center !important;
  }

  .WhySmartOffice .heading h2 {
    text-align: center !important;
  }

  .WhySmartOffice .hr-tab .nav {
    flex-direction: row !important;
    flex-wrap: nowrap;
    gap: 16px;
    border: 0px;
  }

  .WhySmartOffice .hr-tab .nav .nav-item {
    flex: 1 0 auto;
  }

  .WhySmartOffice .hr-tab .nav .nav-item button {
    padding: 12px 16px !important;
    margin-bottom: 0;
    width: 100%;
  }

  .WhySmartOffice .hr-tab .nav .nav-item button h4 {
    margin-bottom: 0;
    text-align: center;
  }

  .WhySmartOffice .hr-tab .nav .nav-item button p {
    display: none;
  }

  .WhySmartOffice .hr-tab ul .nav-item button::after {
    height: 0;
  }

  .WhySmartOffice .hr-tab .heading {
    max-width: none;
  }

  .Howtowork {
    padding: 32px 0;
  }

  .WhySmartOffice {
    padding: 40px 0;
  }

  .racipeLibrary {
    display: none;
  }

  .core-hr {
    padding: 24px 0;
  }

  .core-hr .heading {
    max-width: unset;
    margin-left: 0px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .core-hr .heading h2 {
    text-align: center;
  }

  .core-hr .heading p {
    text-align: center;
    margin: 0 auto;
  }

  .core-hr .heading {
  }

  .core-hr .card-info-wrapper:nth-child(2) {
    margin-top: 0px;
  }

  .core-hr .card-info-wrapper:nth-child(3) {
    margin-top: 0px;
  }

  .blockChain {
    padding: 40px 0;
  }

  .blockChain .hero-title-animate {
    flex-direction: column !important;
  }

  .blockChain .hero-title {
    z-index: 0;
  }

  .OptimizeTime .heading h2 {
    text-align: center;
  }

  .OptimizeTime .heading p {
    text-align: center;
  }

  .OptimizeTime .hr-list-item ul {
    padding-left: 33px;
  }

  .StartWithUs {
    padding: 24px 0;
  }

  .StartWithUs .heading {
    text-align: center !important;
  }

  .StartWithUs .heading h2 {
    text-align: center !important;
    max-width: unset;
  }

  .StartWithUs .heading p {
    text-align: center !important;
    max-width: unset;
  }

  .StartWithUs .heading .btn-group {
    justify-content: center !important;
    margin: 40px auto;
    width: 100% !important;
  }

  .StartWithUs .heading .btn-group .btn {
    width: 100%;
  }

  .StartWithUs .img img {
    width: 100%;
  }

  .integrate {
    padding: 40px 0;
  }
}

@media screen and (max-width: 767px) {
  /* home hero responsive start */
  .hero-section .logo-slider {
    right: 0rem;
  }

  .hero-section .logo-slider .marquee-wrap {
    /* gap: 15px; */
    right: -2rem;
  }

  /* .hero-section .logo-slider .marquee-item {
    margin: 16px 0px;
  } */

  .hero-section .logo-slider .marquee-item {
    margin: 16px 0px;
    gap: 8px;
    padding: 16px;
  }

  .hero-section .logo-slider .marquee-item .img img {
    width: 32px;
    height: 32px;
    margin: 16px;
  }

  .hero-section .logo-slider .marquee-item .content p {
    font-size: 12px;
    line-height: 18px;
  }
  /* home hero responsive end */

  .keyfeature .animate-key-card {
    justify-content: center;
  }

  .keyfeature .animate-key-card .info-card {
    position: relative;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    flex: 0 0 auto;
    width: 100%;
    max-width: 45%;
    min-width: 45%;
    min-height: auto;
  }

  .keyfeature .animate-key-card .info-card .img-content img {
    max-width: 30px;
    max-height: 30px;
  }

  .Howtowork .works-part .works-right {
    width: 100%;
    padding-left: 0;
  }

  .Howtowork .works-part .works-right .heading .section-name {
    text-align: center;
  }

  .Howtowork .works-part .works-right .heading h2 {
    text-align: center !important;
    padding: 0 4%;
  }

  .Howtowork .works-part .works-slider .swiper {
    padding: 0px 0 56px;
  }

  .Howtowork .works-part .swiper-scrollbar {
    height: 0;
  }

  .Howtowork .works-part .slider-pagination-btn {
    justify-content: center;
  }

  .Howtowork .works-part .works-right ul li {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    padding-left: 0.8rem;
    margin-bottom: 12px;
    position: relative;
    text-align: left;
  }

  .Howtowork .works-part .works-right ul li::before {
    width: 4px;
    height: 4px;
  }

  .core-hr .row {
    flex-direction: column-reverse;
  }

  .OptimizeTime .row {
    flex-direction: column;
  }

  .WhySmartOffice .heading h2 {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 29px;
  }

  .WhySmartOffice .hr-tab .heading .hr-list-item ul {
    text-align: left;
  }

  .StartWithUs .heading .btn-group {
    flex-direction: column;
    gap: 16px !important;
    width: 100% !important;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 575px) {
  /* home hero responsive start */
  .hero-section .logo-slider {
    height: 400px;
  }

  .hero-section .logo-slider .marquee-item {
    margin: 8px 0px;
    gap: 8px;
    padding: 10px 8px;
  }

  .hero-section .logo-slider .marquee-item .img img {
    width: 24px;
    height: 24px;
    margin: 10px;
  }

  .hero-section .logo-slider .marquee-item .content p {
    font-size: 8px;
    line-height: 12px;
  }
  /* home hero responsive end */
}
