/* font-family css */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
/* navbar css */
@import url("./navbar.css");
/* footer css */
@import url("./footer.css");
/* auth css */
@import url("./auth.css");

* {
  padding: 0;
  margin: 0;
}

:root {
  --primary-color: rgb(1, 63, 215);
  --dark-color: #243757;
  --bg-color: rgba(237, 241, 248, 0.4);
}

body {
  font-family: "Manrope", sans-serif !important;
}

a {
  text-decoration: none;
}

ol,
ul,
li {
  list-style-type: none;
  padding-left: 0;
}

.bg-primary-thin {
  transition: 0.5s;
}

.bg-primary-thin:hover {
  background-color: white;
  color: var(--primary-color);
}

.form-check-input:focus,
.form-control:focus {
  box-shadow: none;
  border-color: rgba(145, 158, 171, 0.32);
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-dark {
  color: var(--dark-color) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-thin {
  background: #e6edff;
}

.bg-primary-thin {
  background-color: #e6edff;
}
.bg-shadow {
  background-color: var(--bg-color);
}

/* max width */
.max-w-546 {
  max-width: 546px;
}

.max-w-270 {
  max-width: 270px;
}

.pointer {
  cursor: pointer;
}

/* btn */
.btn {
  padding: 8px 30px;
}
.btn:focus-visible {
  box-shadow: none !important;
}
.btn-outline-primary:hover,
.btn-light:hover {
  background: #013fd7;
  color: white !important;
}
.btn-outline-primary:hover svg path {
  stroke: #fff;
}

.btn-primary:hover {
  background: white;
  color: #013fd7 !important;
  border: 1px solid #013fd7;
}

.radius-8 {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.radius-10 {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.radius-16 {
  border-radius: 16px !important;
  -webkit-border-radius: 16px !important;
  -moz-border-radius: 16px !important;
  -ms-border-radius: 16px !important;
  -o-border-radius: 16px !important;
}

.radius-12 {
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
}

.radius-24 {
  border-radius: 24px !important;
  -webkit-border-radius: 24px !important;
  -moz-border-radius: 24px !important;
  -ms-border-radius: 24px !important;
  -o-border-radius: 24px !important;
}

.radius-20 {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.btn-primary {
  background: #013fd7;
  color: white !important;
}

.btn-light {
  background: white;
  color: #013fd7 !important;
  border: 1px solid #013fd7;
}

.btn-dark {
  background: white;
  color: #212b36;
  font-weight: 700;
  border: 1px solid #919eab;
}

.CSK-900 {
  color: #031430;
}

.-right-4 {
  right: -4%;
}

.-top-1 {
  top: -1rem;
}

/* btn */

/* Css class for fs  */

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.fs-40 {
  font-size: 40px;
}

.fs-48 {
  font-size: 48px;
}

.fs-64 {
  font-size: 64px;
}

/* font-weight */
.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

/* font-weight */

/* color */
.CSK-900 {
  color: #031430;
}

/* color */
/* max width */
.max-w-546 {
  max-width: 546px;
}

.mt-64 {
  margin-top: 64px;
}

.pt-64 {
  padding-top: 64px;
}

.pb-64 {
  padding-bottom: 64px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-64 {
  margin-bottom: 64px;
}

/* Components for info card  */

/* Components for info card  */
.card {
  position: relative;
}

.card p {
  color: rgba(45, 68, 108, 1);
}

.card-shadow {
  box-shadow: 0px 12px 30px rgba(145, 158, 171, 0.16);
}

.card-border {
  border: 1px solid rgba(91, 119, 147, 0.24) !important;
}

/* info card  */

.info-card h3 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.info-card p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  color: #243757;
  opacity: 0.8;
  max-width: 277px;
}

.card .arrow-icon-hover {
  width: 40px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  margin: 10px;
}

.card:hover .arrow-icon-hover {
  visibility: visible !important;
  opacity: 1;
}

/* info card  */

video {
  width: 100%;
  outline: none;
  border: none;
}

/* Presetup project  ended */

/* Blog component  */

.blog-card h3 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #101828;
}

.blog-card .text-content p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.blog-card .text-content {
  padding: 16px 32px;
  background-color: #f5f6f8;
}

.blog-card .text-content p {
  max-width: 324px;
  width: 100%;
}
.slick-slider .blog-card .text-content p {
  max-width: 100%;
  width: 100%;
}

.card-badge {
  width: fit-content;
}

.card-badge {
  padding: 4px;
}

.card-badge span {
  padding: 4px 10px;
}

.published-info span {
  font-weight: 600;
}

/* Blog component  */

/* centered heading component ----------------------------- */
.heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
}

.heading h2 {
  font-family: "Manrope";
  color: #243757;
  text-align: center;
  font-weight: 800;
}

.heading p {
  font-family: "Manrope";
  color: #243757;
  font-style: normal;
  font-weight: 400;
}

.centered-heading h2 {
  max-width: 709px;
  width: 100%;
  margin: auto;
}

.centered-heading p {
  font-family: "Manrope";
  color: #243757;
  font-style: normal;
  font-weight: 400;
  max-width: 450px;
  width: 100%;
  margin: auto;
  text-align: center;
}

.left-heading h2,
.left-heading p {
  max-width: 550px;
  width: 100%;
}

.left-heading p {
  max-width: 414px;
  width: 100%;
}

/* centered heading ended -------------------------------------- */

.newsletter {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 530px;
}

.newsletter .newsletter-content {
  max-width: 902px;
  width: 100%;
}

/* Product card  */
.product-card {
  padding: 0px 40px;
}

.product-card ul {
  margin-left: 25px;
}

.product-card .img-content img {
  padding: 25px;
}

.product-card ul li {
  color: #3f466a;
  margin-bottom: 5px;
  list-style: url("../../assets/images/comp/check.png");
}

/* Status card  */

.stat-card {
  background: rgba(237, 241, 248, 0.5);
}

/* Info card  */
.info-card-2 {
  padding: 5px 13px;
}

/* Info card loading */
.overview-section .img {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-30px);
  }

  100% {
    transform: translatey(0px);
  }
}

.loading-spin {
  width: 36px;
  height: 36px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: relative;
  border: 1px solid rgba(1, 63, 215, 0);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.card:hover .loading-spin {
  border: 1px solid rgba(1, 63, 215, 1);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.card .loading-spin svg path {
  stroke: rgba(1, 63, 215, 1);
}

/* .loading-spin .circle-loading {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
} */

/* .card:hover .loading-spin .circle-loading {
  background: transparent;
  background: #013fd7;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
} */

/* @keyframes rotating-spinner {
  from {
    transform: rotate(-0deg);
    -webkit-transform: rotate(-0deg);
    -moz-transform: rotate(-0deg);
    -ms-transform: rotate(-0deg);
    -o-transform: rotate(-0deg);
  }

  to {
    transform: rotate(-360deg);
  }
} */

/* schedule-card compnent started  */
.schedule-card-heading {
  border-bottom: 1px solid #dddddd;
  width: fit-content;
  margin: 0 auto;
}

.schedule-card .text-content p {
  max-width: 278px;
  margin: auto;
}

/* schedule-card compnent ended  */

/* #content card component  */
.content-card .text-content {
  max-width: 324px;
  width: 100%;
  margin: auto;
}
.content-card .text-content h2 {
  min-height: 48px;
}

/* #content card component ended  */

.details-section-6x6 .details {
  font-style: normal;
  line-height: 30px;
  /* Dark-new-2nd */
  color: #243757;
}

/* Faq component css */
.accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button::after {
  background-image: url("../images/comp/arrow.png");
  width: 30px !important;
  height: 30px !important;
  background-size: 30px !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../images/comp/arrow_active.png");
  width: 30px !important;
  height: 30px !important;
  background-size: 30px !important;
}

/* hr-tab component  */
.hr-tab .nav-pills {
  border: 1px solid rgba(36, 55, 87, 0.16);
  /*border-radius: 24px;
  */ /*padding: 40px;
  */
  border-radius: 11px;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  -ms-border-radius: 11px;
  -o-border-radius: 11px;
}

.hr-tab .nav-pills::-webkit-scrollbar {
  width: 100%;
  height: 10px;
}

.hr-tab ul .nav-item button {
  background: #ffffff;
  border: 2px solid rgba(1, 63, 215, 0.4);
  box-shadow: 0px 12px 30px rgba(145, 158, 171, 0.16);
  border-radius: 11px;
  border: 1px solid rgba(91, 119, 147, 0.24);
  position: relative;
  /*margin-bottom: 50px;
  */
  transition: 0.5s;
  margin: 20px 40px;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  -ms-border-radius: 11px;
  -o-border-radius: 11px;
}

/* .hr-tab ul .nav-item button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: rgba(36, 55, 87, 1);
  bottom: -25px;
  opacity: 0.12;
  left: 0;
}

.hr-tab ul .nav-item:last-child button::after {
  display: none;
} */

/* .hr-tab ul .nav-item:last-child button {
  margin-bottom: 0px;
} */

.hr-tab ul .nav-item button.active {
  background-color: var(--primary-color);
  border: 1px solid rgba(1, 63, 215, 0.4);
  margin: 0;
}

.hr-tab ul .nav-item button.active h4 {
  color: #fff !important;
}

.hr-tab ul .nav-item button p {
  color: #243757;
  opacity: 0.6;
}

.hr-tab ul .nav-item button.active p {
  color: #fff;
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .hr-tab .nav-pills {
    padding: 15px !important;
  }
  .hr-tab ul .nav-item button {
    margin: 0;
  }
}

@media screen and (min-width: 992px) {
  .nav-link img {
    display: none !important;
  }

  .offcanvas {
    display: none;
  }

  .offcanvas-backdrop.show {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .hr-tab .nav-pills {
    border: none;
    flex-direction: row !important;
    display: flex !important;
    flex-wrap: nowrap !important;
    gap: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .hr-tab .nav-pills button {
    margin-bottom: 0px !important;
  }

  .hr-tab .nav-pills button p {
    display: none;
  }
}

.fullwidth-heading h2 {
  max-width: 855px;
  margin: auto;
  width: 100%;
}

/* MIni card component  */
.mini-card-wrapper {
  max-width: 926px;
  margin: auto;
  width: 100%;
}

.mini-card {
  border: 1px solid rgba(145, 158, 171, 0.24);
  box-shadow: 0px 24px 48px rgba(145, 158, 171, 0.16);
  padding: 20px 15px;
  max-width: 200px;
}

/* Company card component  */

/* Company card component  */

/* Get -in - touch component  */
.get-in-touch {
  min-height: 471px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafbfc;
}

.get-in-touch img:nth-child(2) {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border-color: #fff;
  position: relative;
}

.get-in-touch img:nth-child(1) {
  margin-right: -10px;
}

.get-in-touch img:nth-child(3) {
  margin-left: -10px;
  z-index: 100;
}

.get-in-touch img {
  border: 5px solid white;
  border-radius: 50%;
}

.get-in-touch .text-content {
  max-width: 400px;
  width: 100%;
}

/* Gradient Text */
.gradient-text {
  background: linear-gradient(270.08deg, #01ccff 0.06%, #013fd7 102.39%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* Gradient Text */

@media screen and (min-width: 769px) {
  .mobile-footer {
    display: none;
  }
}

@media screen and (max-width: 769px) {
  .footer {
    display: none;
  }

  .mobile-footer {
    display: block;
  }
}

.mobile-footer .wrapper {
  display: flex;
  flex-wrap: wrap;
}

.mobile-footer .wrapper li {
  flex: 0 0 auto;
  width: 50%;
}

.mobile-footer .wrapper li:nth-of-type(even) {
  text-align: right;
}

.mobile-footer .accordion-item {
  border: none;
  box-shadow: none;
  background-color: transparent;
  margin-bottom: 24px;
}

.mobile-footer .accordion-header {
  background: #304362;
  border-radius: 5px;
  color: #edf1f8;
  border: none;
}

.mobile-footer .accordion-item button {
  color: white;
}

.mobile-footer .accordion-body {
  background: #304362;
}

/* MOpbile footer  */

/* Offcanvus component  started */
.offcanvas-header {
  border-bottom: 1px solid rgba(91, 119, 147, 0.12) !important;
}

.offcanvas-header button {
  background: none !important;
}

.offcanvas-header button:focus {
  box-shadow: none;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  border-bottom: none;
}

.offcanvas-header button {
  border: none;
}

.offcanvas-header button img {
  height: 30px;
  width: 30px;
}

.level-2-mob-menu-card h5 {
  color: #212b36;
}

.level-2-mob-menu-card p {
  color: #2d446c;
  opacity: 0.8;
}

.offcanvas-body h5 {
  border-bottom: 1px solid rgba(91, 119, 147, 0.12) !important;
}

.canvas-container {
  max-width: 90%;
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 769px) {
  .offcanvas {
    width: 100% !important;
  }
}

/* Offcanvus component  ended */

/* Css class for fs  */
.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.fs-40 {
  font-size: 40px;
}

.fs-48 {
  font-size: 48px;
}

.fs-64 {
  font-size: 64px;
}

.fs-62 {
  font-size: 62px;
}

.mt-64 {
  margin-top: 64px;
}

.pt-64 {
  padding-top: 64px;
}

.pb-64 {
  padding-bottom: 64px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-72 {
  margin-bottom: 72px;
}

.is-link-pt {
  padding-top: 6% !important;
}

/* Circle animation for card  */

/* animation */
.circle-animate {
  animation: spin-slow 30s linear infinite;
  -webkit-animation: spin-slow 30s linear infinite;
}

/* @keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

/* marl loading */
.loading-spin {
  width: 36px;
  height: 36px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: relative;
}

/* .loading-spin .circle-loading {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgba(1, 62, 215, 1);
  /* border-top: 2px solid rgba(1, 62, 215, 1);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.info-card:hover .loading-spin .circle-loading {
  border: 2px solid rgba(1, 62, 215, 1);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
} */

/* @keyframes rotating-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

/* Circle animation for card end */

/* Component for navabar sticky css */
.sticky {
  position: fixed;
  top: -60px;
  width: 100%;
  z-index: 2;
  animation: goDown 0.5s ease-in-out forwards;
  -webkit-animation: goDown 0.5s ease-in-out forwards;
  background-color: var(--Background-Color);
}

@keyframes goDown {
  0% {
    top: -60px;
  }

  100% {
    transform: translateY(60px);
  }
}

.sticky + .content {
  padding-top: 60px;
}

/* Component for navabar sticky css ended */

/* scroll to top */
#btn-back-to-top {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

#btn-back-to-top.hidden {
  visibility: hidden;
  opacity: 0;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

#btn-back-to-top.visible {
  position: fixed;
  bottom: 10%;
  right: 20px;
  background-color: #013fd7;
  visibility: visible !important;
  opacity: 1;
  transition: all linear 0.3s;
  z-index: 1;
}

/* scroll to top */

/* custom scrollbar */

/* ===== Scrollbar CSS ===== */
/* Firefox */
body {
  scrollbar-width: auto;
  scrollbar-color: #013fd7 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}
*::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

*::-moz-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

*::-moz-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}

/* custom scrollbar */

/* Responsive code started from here 
#####################################
################################### */
@media screen and (max-width: 1020px) {
  .fs-32 {
    font-size: 22px;
  }

  .fs-40 {
    font-size: 30px;
  }

  .fs-48 {
    font-size: 38px;
  }

  .fs-64 {
    font-size: 54px;
  }

  .fs-62 {
    font-size: 52px;
  }

  .mt-64 {
    margin-top: 44px;
  }

  .pt-64 {
    padding-top: 44px;
  }

  .pb-64 {
    padding-bottom: 44px;
  }

  .mt-80 {
    margin-top: 60px;
  }

  .mb-80 {
    margin-bottom: 60px;
  }

  .mb-64 {
    margin-bottom: 44px;
  }
  .is-link-pt {
    /* padding-top: 6% !important; */
    scroll-padding-top: 100px;
  }
}

@media screen and (max-width: 992px) {
  .fs-12 {
    font-size: 12px;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-16 {
    font-size: 14px;
  }

  .fs-15 {
    font-size: 15px;
  }

  .fs-18 {
    font-size: 18px;
  }

  .fs-20 {
    font-size: 16px;
  }

  .fs-24 {
    font-size: 24px;
  }

  .fs-32 {
    font-size: 32px;
  }

  .fs-32 {
    font-size: 17px;
  }

  .fs-40 {
    font-size: 25px;
  }

  .fs-62 {
    font-size: 46px;
  }

  .fs-48 {
    font-size: 23px;
  }

  .fs-64 {
    font-size: 28px;
  }

  .mt-80 {
    margin-top: 30px;
  }

  .mb-80 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .fs-12 {
    font-size: 12px !important;
  }

  .fs-14 {
    font-size: 12px;
  }

  .fs-16 {
    font-size: 14px;
  }

  .fs-15 {
    font-size: 13px;
  }

  .fs-18 {
    font-size: 14px;
  }

  .fs-20 {
    font-size: 16px;
  }

  .fs-24 {
    font-size: 15px;
  }

  .fs-32 {
    font-size: 16px;
  }

  .fs-40 {
    font-size: 22px;
  }

  .fs-48 {
    font-size: 20px;
  }

  .fs-62 {
    font-size: 36px;
  }

  .fs-64 {
    font-size: 28px;
  }

  .mt-64 {
    margin-top: 20px;
  }

  .pt-64 {
    padding-top: 20px;
  }

  .pb-64 {
    padding-bottom: 20px;
  }

  .mt-80 {
    margin-top: 20px;
  }

  .mb-80 {
    margin-bottom: 20px;
  }

  .mb-64 {
    margin-bottom: 20px;
  }

  .newsletter {
    min-height: 362px;
    padding: 10px;
  }

  .newsletter a {
    margin-top: 20px !important;
    width: 100% !important;
  }

  .hr-tab .nav-pills {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
}
