.ligal-nav {
    border: 1px solid rgba(91, 119, 147, 0.12);
}

.ligal-nav .active {
    background: #e6edff !important;
    color: var(--primary-color) !important;
    border: none;
}

@media screen and (max-width:768px) {
    .ligal-nav>div {
        flex-direction: column;
    }

    .ligal-nav>div h4 {
        margin-bottom: 32px;
    }
}