.faq-page .fullwidth-heading {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.faq-page .fullwidth-heading {
    background-image: url('../images/hero/hero bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}

.faq-page .fullwidth-heading h2, .faq-page .fullwidth-heading p {
    color: white !important;
}

.faq-page .faq-hero input {
    max-width: 320px;
    width: 100%;
    margin: auto;
}

.faq-hero .input-wrapper {
    position: relative;
    max-width: 320px;
    width: 100%;
    margin: auto;
}

.faq-hero .input-wrapper::before {
    content: '';
    position: absolute;
    background-image: url('../images/comp/icons/search.png');
    left: 15px;
    top: 11px;
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;

}

@media screen and (max-width:768px) {
    .get-in-touch {
        min-height: 250px;
    }
}