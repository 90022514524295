.contact-page .fullwidth-heading {
  margin-top: 96px;
  margin-bottom: 96px;
}

.contact-page .fullwidth-heading p.desc {
  color: rgba(102, 112, 133, 1);
}

.contact-card {
  background-color: #edf1f8;
  min-height: 200px;
}

.contact-card p {
  color: #667085;
}

.contact-page .address-info p:last-child {
  color: #2d446c;
  max-width: 388px;
  width: 100%;
}

.contact-page .visitsection {
  background: rgba(204, 219, 255, 0.2);
  /* min-height: 514px; */
  min-height: 370px;
  display: flex;
  align-items: center;
}

.contact-page .contact-form {
  max-width: 1116px;
  width: 100%;
}

.contact-page .contact-form input {
  margin-bottom: 5px;
  padding: 15px;
}

.contact-page .contact-form label {
  margin-bottom: 10px;
}

.contact-form label {
  color: #344054;
}
.contact-page .contact-form .policy {
  text-decoration: underline;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  margin-left: 5px;
}
.contact-page .contact-form .PhoneInput {
  padding: 15px;
  margin-bottom: 5px;
}
.contact-page .contact-form .PhoneInputInput {
  margin-bottom: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-shadow: none;
}

/* Responsive code started from here  */

@media screen and (max-width: 993px) {
  .contact-form .form-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .contact-page .fullwidth-heading {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
