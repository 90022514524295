.Compare-Page .hero {
  background: linear-gradient(91deg, #faf9f7 11.64%, #f6f7fa 88.33%);
  padding-top: 37px;
  padding-bottom: 64px;
  margin-bottom: 104px;
}

.Compare-Page .breadcrumb {
  border-bottom: 0px;
}

.Compare-Page .breadcrumb .breadcrumb-item a {
  color: #3c5b90;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Compare-Page .breadcrumb .breadcrumb-item.active {
  color: #013fd7;
  font-size: 16px;
  font-weight: 800;
}

.Compare-Page h2 {
  color: #121212;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 62px;
}

.Compare-Page p {
  color: #121212;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  max-width: 458px;
  margin-bottom: 43px;
}

.Compare-Page .appvs {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 46px;
}

.Compare-Page .appvs h4 {
  color: #121212;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 62px;
  opacity: 0.6;
}

.Compare-Page .hero-img {
  position: relative;
  bottom: -11.3rem;
}

/* hero */
/* compare */
.Compare-Page .compare {
  padding: 72px 0;
}

.Compare-Page .compare h4 {
  color: #243757;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.Compare-Page .compare h2 {
  color: #121212;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 28.8px;
  max-width: 446px;
  margin: 0 auto 12px;
}

.Compare-Page .compare h2 a {
  color: #013fd7;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
}

.Compare-Page .compare p {
  color: rgba(18, 18, 18, 0.5);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  max-width: 736px;
  margin: 0 auto 40px;
}

.Compare-Page .compare .zui-scroller .zui-table tr {
  border: 0px;
}

.Compare-Page .compare .zui-scroller .zui-table tr th {
  color: #121212;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 33.6px;
  letter-spacing: 0.048px;
  border: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Compare-Page .compare .zui-scroller .zui-table tr th:first-child {
  text-align: left;
}

.Compare-Page .compare .zui-scroller .zui-table tr th:nth-child(2) {
  border-radius: 8px 8px 0px 0px;
  background: #243757;
  /* Dropdown */
  box-shadow: -20px 20px 40px -4px rgba(0, 0, 0, 0.24),
    0px 0px 2px 0px rgba(0, 0, 0, 0.24);
}

.Compare-Page .compare .zui-scroller .zui-table tr th:nth-child(3) {
  border-radius: 8px 8px 0px 0px;
  background: #00020c;
}

.Compare-Page .compare .zui-scroller .zui-table tr td:first-child {
  text-align: left;
  color: rgba(8, 3, 22, 0.8);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.Compare-Page .compare .zui-scroller .zui-table tr td.title:first-child {
  color: #121212;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
  /* 33.6px */
  letter-spacing: 0.048px;
  padding-left: 0;
}

.Compare-Page .compare .zui-scroller .zui-table tr td {
  border: 0px;
  padding: 20px 24px;
  color: #0e0006;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 24px */
}

.Compare-Page .compare .zui-scroller .zui-table tr td:nth-child(2) {
  border-left: 2px solid #013fd7 !important;
  border-right: 2px solid #013fd7 !important;
  color: #013fd7;
}

.Compare-Page
  .compare
  .zui-scroller
  .table-striped
  > tbody
  > tr:nth-of-type(odd)
  td:nth-child(2) {
  --bs-table-accent-bg: rgba(1, 63, 215, 0.08);
}

.Compare-Page
  .compare
  .zui-scroller
  .table-striped
  > tbody
  > tr:nth-of-type(odd)
  td:nth-child(3) {
  --bs-table-accent-bg: rgba(255, 166, 0, 0.12);
}

.Compare-Page .compare .zui-scroller .zui-table tr:last-child td:nth-child(2) {
  border-bottom: 2px solid #013fd7 !important;
}

/* one-service */
.one-service {
  margin-bottom: 64px;
}

.one-service h2 {
  color: #243757;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 1rem;
  max-width: 400px;
}

.one-service p {
  color: #898989;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  max-width: 452px;
}

/* one-service */

/* responsive */
@media (min-width: 768px) and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .Compare-Page .breadcrumb .breadcrumb-item,
  .Compare-Page .breadcrumb .breadcrumb-item.active {
    font-size: 12px;
  }

  .Compare-Page .hero {
    margin-bottom: 0;
  }

  .Compare-Page .breadcrumb {
    margin-top: 0px;
    padding: 0;
  }

  .Compare-Page h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .Compare-Page .hero-img {
    bottom: -2rem;
  }

  .Compare-Page .compare .zui-scroller .zui-table tr td.title:first-child {
    font-size: 16px;
  }

  .Compare-Page .compare .zui-scroller .zui-table tr td {
    justify-content: space-between;
    gap: 20px;
  }

  .Compare-Page .compare {
    padding: 40px 0;
  }

  .Compare-Page .compare h2,
  .Compare-Page .compare h2 a {
    font-size: 16px;
  }

  .Compare-Page .compare p {
    margin: 0 auto 20px;
  }

  .Compare-Page .compare .zui-scroller .zui-table tr td {
    font-size: 14px;
  }

  .one-service {
    margin-bottom: 0px;
  }

  .one-service h2 {
    font-size: 18px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .one-service p {
    font-size: 14px;
  }
}

/* responsive */
