/* Deive page  */
.device-page .details-section-6x6 {
    margin-top: 100px;
}

.device-page .details-section-6x6:last-child {
    margin-bottom: 120px;
}

.device-page .text-content {
    max-width: 451px;
    margin: auto;
    width: 100%;
}

.device-details-page .device-hero {
    min-height: 800px;
}

.device-details-page .desc {
    max-width: 651px;
    width: 100%;
    margin: auto;
}

@media screen and (max-width:768px) {
    .device-page .hero-title {
        text-align: center;
        margin-top: 50px;
    }

    .device-page .hero-title a {
        margin-bottom: 50px;
        width: 100% !important;
    }

    .device-page .device-hero {
        margin-bottom: 50px;
    }

    .device-page .fullwidth-heading h2 {
        margin-bottom: 16px;
    }

    .device-page .details-section-6x6 h2 {
        text-align: center;
        margin: 40px 0px;
    }

    .device-page .details-section-6x6 .btn-light {
        width: 100% !important;
        margin-bottom: 20px !important;
    }

    .device-page .details-section-6x6 {
        margin-top: 0px;
    }
}