/* training-banner */
.training-banner {
  background: #243757;
  padding-top: 57px;
  margin-bottom: 80px;
}
.training-banner h2 {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 62px;
  letter-spacing: -2px;
  margin-bottom: 1rem;
}
.training-banner form {
  max-width: 320px;
  position: relative;
  margin-bottom: 1rem;
}
.training-banner form input {
  padding-left: 2.8rem;
}
.training-banner form span {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.training-banner p {
  color: #e6edff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

/* training-banner */

/* training */
.training .breadcrumb {
  border-bottom: 0;
}
.training .breadcrumb .breadcrumb-item a {
  color: #2d446c;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 214.286% */
}
.training .breadcrumb .breadcrumb-item.active {
  color: #0f1724;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 187.5% */
}
.training .question-card {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 12px 30px 0px rgba(145, 158, 171, 0.16);
  margin-bottom: 56px;
  padding: 24px 24px;
  position: relative;
  margin-right: 4px;
  margin-left: 31px;
  margin-top: 24px;
}
.training .question-card .img {
  position: absolute;
  top: -2rem;
  left: -2rem;
}
.training .question-card h4 {
  color: #013fd7;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 21.6px */
  margin-bottom: 12px;
}
.training .question-card ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0;
  gap: 28px;
}
.training .question-card ul li:nth-child(2) {
  list-style-type: disc;
}
.training .question-card ul li:nth-child(2)::marker {
  color: #243757;
}
.training .question-card ul li span {
  color: #243757;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin-bottom: 12px;
  display: block;
}
.training .question-card p {
  color: #243757;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin-bottom: 24px;
}
.training .question-card a {
  color: #664900;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 185.714% */
  border-radius: 6px;
  background: #ffe299;
  padding: 6px 8px;
}

/* training */

/* question */
.question .col-12 {
  border-radius: 10px;
  background: #fafbfc;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 64px;
  margin-bottom: 64px;
}
.question h4 {
  color: #253858;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px; /* 141.667% */
  margin-bottom: 8px;
}
.question p {
  color: #52525b;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  max-width: 401.387px;
  margin: 0 auto 25px;
}
.question a {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 162.5% */
  border-radius: 8px;
  background: #013fd7;
  padding: 11px 22px;
}

/* question */

/* article */
.article h2 {
  color: #013fd7;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 62px;
}
.article .breadcrumb {
  margin-top: 9px;
  border-bottom: 0;
}
.article .breadcrumb .breadcrumb-item a {
  color: #2d446c;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 214.286% */
}
.article .breadcrumb .breadcrumb-item.active {
  color: #0f1724;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 187.5% */
}
.article .article-card {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  padding: 24px 24px;
  margin-bottom: 24px;
}
.article .article-card h4 {
  color: #121212;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 21.6px */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin-bottom: 13px;
}
.article .article-card p {
  overflow: hidden;
  color: #3c5b90;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  margin-bottom: 24px;
}
.article .article-card p a {
  color: #0f1724;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  border: 0;
  background: transparent;
  padding: 0;
}
.article .article-card a {
  color: #664900;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 185.714% */
  border-radius: 6px;
  background: var(--harvest-gold-200, #ffe299);
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 2px 8px;
}

.article .btn {
  color: #0f1724;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid #dbe3f0;
  margin-top: 32px;
  padding: 16px 24px;
}

/* article */

/* single-product-training */
/* Optional styles for printing */
@media print {
  body * {
    visibility: hidden;
  }
  #printable-div,
  #printable-div * {
    visibility: visible;
  }
}
.single-product-training {
  margin-bottom: 80px;
}
.single-product-training .breadcrumb {
  margin-top: 9px;
  border-bottom: 0;
}
.single-product-training .breadcrumb .breadcrumb-item a {
  color: #2d446c;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 214.286% */
}
.single-product-training .breadcrumb .breadcrumb-item.active {
  color: #0f1724;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 187.5% */
}
.single-product-training .copy-print-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex !important;
  align-items: center;
  gap: 24px;
}
.single-product-training .copy-print-btn button {
  background: transparent;
  border: 0;
}
.single-product-training .border-design {
  border-radius: 8px;
  border: 1px solid rgba(91, 119, 147, 0.24);
  background: #fff;
  padding: 24px 24px;
}
.single-product-training .single-product-training-content h1 {
  color: #121212;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 21.6px;
  margin-bottom: 24px;
}
.single-product-training .single-product-training-content h4 {
  color: #0f1724;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.single-product-training .single-product-training-content p {
  color: #1e2d48;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  margin-bottom: 1rem;
}
.single-product-training .share {
  margin-top: 24px;
}
.single-product-training .share ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
.single-product-training .share ul li {
  flex: 0 0 auto;
}
.single-product-training .share ul li h5 {
  color: #135;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.056px;
}
.single-product-training .share ul li button {
  width: 32px;
  height: 32px;
  background-color: #f4f4f4;
  padding: 4px 4px;
  border: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.single-product-training .position-sticky {
  top: 100px;
}
.single-product-training .position-sticky .onThisPage {
  border-radius: 8px;
  border: 1px solid rgba(91, 119, 147, 0.24);
  margin-bottom: 16px;
  overflow: hidden;
}
.single-product-training .position-sticky .onThisPage h2 {
  color: #0f1724;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border-bottom: 1px solid rgba(91, 119, 147, 0.24);
  padding: 26px 16px;
  margin-bottom: 0;
}
.single-product-training .position-sticky .onThisPage .list-group {
  border-radius: 0;
}
.single-product-training .position-sticky .onThisPage a {
  color: #1e2d48;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  border: 0;
  padding: 16px 16px;
  display: block;
}
.single-product-training .position-sticky .onThisPage a.active {
  color: #fff;
  background: #013fd7;
}

.single-product-training .position-sticky .onThisPage ul {
  margin: 0;
  padding: 0;
}
.single-product-training .position-sticky .onThisPage .seeAll {
  color: #013fd7;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  display: block;
  padding: 16px;
  margin-bottom: 16px;
}

/* single-product-training */

/* responsive */
@media screen and (min-width: 769px) {
  .single-product-training .desk-hidden {
    display: none;
  }
  .single-product-training .mob-hidden {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .single-product-training .single-product-training-content h1 {
    margin-top: 3rem;
  }
  .single-product-training .border-design {
    margin-bottom: 1rem;
  }
  .single-product-training .col-lg-4 {
    padding-left: 0;
    padding-right: 0;
  }
  .single-product-training .mob-hidden {
    display: none !important;
  }
}
