/* Plan page css   */

.pricing-switch {
  border: 1px solid #73d3f1;
  border-radius: 8px;
  max-width: fit-content;
  width: 100%;
  margin: auto;
}

.pricing-switch a {
  color: #667085;
}

.pricing-switch .active.btn {
  border: none;
  background-color: var(--primary-color);
  color: white;
}

.package-price span:first-child {
  font-size: 36px;
  font-weight: 900;
}

.package-price span:last-child {
  font-size: 17px;
  font-weight: 500;
  color: #848199;
  margin-left: 10px;
}

.h3.package-name {
  font-weight: 900;
  font-size: 28px;
}

p.package-desc {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;

  color: #848199;
}

.package-features ul li {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #848199;
}

.package-features ul li {
  list-style: url("../../assets/images/comp/check-circle-1.png");
  margin-left: 20px;
  margin-bottom: 10px;
}

.pricing-card {
  max-width: 295px;
  padding: 15px 30px;
  transition: 1s;
}

.pricing-card h5 {
  visibility: hidden;
}

.pricing-card:hover {
  transform: translatey(-30px) scale(1.1);
  z-index: 3;
  background: rgba(1, 63, 215, 1);
  box-shadow: 0px 42px 34px rgba(1, 63, 215, 0.295755);
  border-radius: 26px;
  z-index: 99999;
}
.pricing-card.most-populer {
  transform: translatey(-30px) scale(1.1);
  z-index: 3;
  background: rgba(1, 63, 215, 1);
  box-shadow: 0px 42px 34px rgba(1, 63, 215, 0.295755);
  border-radius: 26px;
  z-index: 99999;
  -webkit-transform: translatey(-30px) scale(1.1);
  -moz-transform: translatey(-30px) scale(1.1);
  -ms-transform: translatey(-30px) scale(1.1);
  -o-transform: translatey(-30px) scale(1.1);
}

.pricing-card:hover .package-features ul li {
  list-style: url("../../assets/images/comp/check-circle-white.png");
}
.pricing-card.most-populer .package-features ul li {
  list-style: url("../../assets/images/comp/check-circle-white.png");
}

.pricing-card:hover:hover p,
.pricing-card:hover:hover h3,
.pricing-card:hover:hover span,
.pricing-card:hover:hover li,
.pricing-card:hover:hover a {
  color: white !important;
}

.pricing-card:hover:hover a.bg-primary-thin {
  color: #013fd7 !important;
}
.pricing-card.most-populer p,
.pricing-card.most-populer h3,
.pricing-card.most-populer span,
.pricing-card.most-populer li,
.pricing-card.most-populer a {
  color: white !important;
}

.pricing-card.most-populer a.bg-primary-thin {
  color: #013fd7 !important;
}

.popular {
  right: 20px;
  top: 5px;
}

.active.pricing-card {
  transform: translatey(-30px) scale(1.1);
  z-index: 3;
  background: rgba(1, 63, 215, 1);
  box-shadow: 0px 42px 34px rgba(1, 63, 215, 0.295755);
  border-radius: 26px;
}

.active.pricing-card .package-features ul li {
  list-style: url("../../assets/images/comp/check-circle-white.png");
}

.active.pricing-card p,
.active.pricing-card h3,
.active.pricing-card span,
.active.pricing-card li,
.active.pricing-card a {
  color: white !important;
}

.active.pricing-card a.bg-primary-thin {
  color: #013fd7 !important;
}

.pricing-card:hover h5 {
  text-align: right;
  width: fit-content;
  visibility: visible;
}
.pricing-card.most-populer .badge {
  text-align: right;
  width: fit-content;
  visibility: visible;
}

.plan-table th {
  background-color: rgba(231, 239, 255, 0.3);
}

.plan-table th,
.plan-table td {
  padding: 15px;
  border: 1px solid #e7efff !important;
}

.bottom-tr td {
  border: none !important;
}

.bottom-tr {
  border: none;
}

.bottom-tr td .btn-light,
.bottom-tr td .btn-dark {
  margin-top: 8px;
}

.bottom-tr td div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pricing-page .get-in-touch {
  background-color: #f2f9ff;
}

.pricing-page .faq {
  margin-top: 120px;
  margin-bottom: 120px;
}

/* Responsive code started for this page  */
.plan-table {
  overflow-x: hidden;
}
table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr {
  /* background-color: #f8f8f8; */
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  text-transform: uppercase;
}

.desktop-th {
  visibility: visible;
  height: auto;
}

@media screen and (max-width: 768px) {
  .pricing-card {
    max-width: calc(100% - 32px);
    padding: 15px 16px;
    margin-bottom: 10px;
  }
  .pricing-card:hover,
  .pricing-card.most-populer {
    transform: translatey(0px) scale(1);
    -webkit-transform: translatey(0px) scale(1);
    -moz-transform: translatey(0px) scale(1);
    -ms-transform: translatey(0px) scale(1);
    -o-transform: translatey(0px) scale(1);
  }
  .desktop-th {
    visibility: hidden;
    height: 0px;
    display: none;
  }

  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
    margin-bottom: 0.625em;
    border: 0px;
  }

  table td {
    display: block;
  }

  table td::before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
  }

  .plan-table h5 {
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0.3px;
  }
  .plan-table td {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .plan-table td:first-child {
    justify-content: flex-start;
    background: #fafbfc;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
