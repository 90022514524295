/* banner */
.banner {
  background: url("./../images/HelpCenter/bg-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 46px 0;
}
.banner h2 {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 62px; /* 193.75% */
  letter-spacing: -2px;
}
.banner p {
  color: #e6edff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 458px;
  margin: 0 auto;
}

/* banner */

/* breadcrumb */
.breadcrumb {
  margin-top: 24px;
  border-bottom: 1px solid #edf1f8;
  padding: 16px 0;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">");
}
.breadcrumb .breadcrumb-item {
  color: #2d446c;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 214.286% */
}
.breadcrumb .breadcrumb-item.active {
  color: #0f1724;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 187.5% */
}

/* breadcrumb */

/* salse page form */
.form {
  margin: 70px 0;
}
.form .form-select.default-select-option {
  color: #013fd7;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.form .form-select {
  /* // A reset of styles, including removing the default dropdown arrow */
  appearance: none;
  /* // Additional resets for further consistency */
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.form select::-ms-expand {
  display: none;
}
.form .form-label {
  color: #1e2d48;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.form .form-control,
.tox-edit-area__iframe body {
  color: #1e2d48;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.form .form-control::placeholder {
  color: #b7c6e1;
}
.form .form-control .PhoneInputInput {
  border: 0;
  outline: none;
  color: #1e2d48;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.form .form-control .PhoneInputInput::placeholder {
  color: #b7c6e1;
}
.tox-tinymce-aux {
  display: none;
}
.form .file-uploading {
  max-width: 100%;
  border-radius: 8px;
  border: 1.5px dashed #013fd7;
  padding: 16px 51px;
  justify-content: center;
}
.form .upload-status {
  color: #1e2d48;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-top: 6px;
}
.form .btn {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 162.5% */
  padding-top: 11px;
  padding-bottom: 11px;
  max-width: 260px;
  width: 100%;
}
.form .tox-statusbar__branding {
  display: none;
}
.billing {
  max-width: 926px;
}
.billing .faq-title {
  color: #0f1724;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 1rem;
  margin-top: 24px;
}
.billing .accordion {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 24px 24px;
}
.billing .accordion .accordion-item {
  border-top: 0px;
  border-right: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
  border-left: 0px;
}
.billing .accordion .accordion-item:last-child {
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px !important;
  border-left: 0px;
}
.billing .accordion .accordion-button {
  font-weight: 700;
  z-index: 1;
  padding-left: 0;
  padding-right: 0;
}
.billing .accordion-body {
  padding-left: 0;
  padding-right: 0;
}
.billing .accordion-body p {
  color: #3c5b90;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}
.billing .accordion-body ul {
  padding-left: 1rem;
}
.billing .accordion-body ul li {
  list-style-type: disc;
  color: #3c5b90;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}
.billing .accordion-help {
  color: #243757;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 214.286% */
  border-radius: 4px;
  border-top: 1px solid #013fd7;
  border-right: 1px solid #013fd7;
  border-bottom: 1px solid #013fd7;
  border-left: 4px solid #013fd7;
  background: rgba(230, 237, 255, 0.2);
  padding: 16px 16px;
}
.billing .accordion-help a {
  color: #243757;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 214.286% */
  text-decoration: underline;
}
.billing .tox .tox-editor-header {
  z-index: 1;
}

/* salse page form */

@media screen and (max-width: 769px) {
  .billing .faq-title {
    font-size: 18px;
  }
}
