.navbar {
  background: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(91, 119, 147, 0.12);
  backdrop-filter: blur(40px);
  z-index: 999;
}

.navbar .container-fluid {
  padding: 0px 96px;
}

.navbar .navbar-nav {
  gap: 32px;
}

.navbar .navbar-nav .nav-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #031430;
  padding: 30px 8px;
  border: 0;
  background-color: transparent;
}

.navbar .navbar-nav .nav-link.active {
  color: #346dfe;
}

.navbar .auth-btn {
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.navbar .auth-btn .btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 119px;
  /* width: 100%; */
}

.navbar .auth-btn .login {
  border: 1px solid #013fd7;
  color: #013fd7;
}

.navbar .auth-btn .signup {
  background: #013fd7;
  color: #ffffff;
}

/* Drop down menu  */
li.nav-item {
  position: unset;
  /* position: relative; */
}

li.nav-item:nth-child(3) .nav-link {
  color: #031430;
}

li.nav-item .nav-link::after {
  display: none;
}

.nav-item div.megamenu {
  width: 100%;
  background: #ffffff;
  box-shadow: -40px 40px 80px -8px rgba(145, 158, 171, 0.24);
  /* position: fixed;
  left: 0;
  top: 80px;
  z-index: 9999; */
  /* display: none; */
  border: 0;
  transition: none;
  margin-top: 0;
}

li.nav-item div.megamenu {
  /* min-height: 60vh; */
  /* padding: 72px 0; */
  /* display: block; */
  padding-top: 72px;
  padding-bottom: 72px;
}

.remover {
  display: none !important;
}

.megamenu-button a {
  /* border-radius: 4px; */
  /* padding: 18px 0px; */

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  color: #243757;
  border-radius: 4px;
  padding: 18px 32px;
  box-shadow: 0px 0px 10px 1px rgba(112, 128, 176, 0.1);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.megamenu-button a .icon {
  max-width: 23px;
  scale: 1.2;
}

.megamenu .level-1-menu-card h5,
.megamenu .level-1-menu-card p {
  color: #212b36;
}

.megamenu-button li a.active {
  /* background-color: rgba(230, 237, 255, 0.6) !important; */
  color: #243757;
}

.megamenu .level-1-menu-card img {
  max-width: 23px;
  width: 100%;
  scale: 0.9;
}

.megamenu-button li a svg path {
  stroke: #212b36;
}

.megamenu-button li a.active svg path,
.megamenu-button li a:hover svg path {
  stroke: #212b36;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.megamenu-button li a:hover {
  /* background: rgba(230, 237, 255, 0.6); */
  color: #243757;
}

.megamenu .menu-item p {
  max-width: 245px;
}

.megamenu .level-1-menu {
  /* display: none !important; */
  /* transition: 0.5s; */
}

.megamenu .level-1-menu .level-1-menu-card>a {
  width: 100%;
  color: #243757;
  border-radius: 4px;
  padding: 18px 32px;
  box-shadow: 0px 0px 10px 1px rgba(112, 128, 176, 0.1);
  transition: all linear 0.4s;
  -webkit-transition: all linear 0.4s;
  -moz-transition: all linear 0.4s;
  -ms-transition: all linear 0.4s;
  -o-transition: all linear 0.4s;
}

.megamenu .level-1-menu .level-1-menu-card:hover>a {
  background-color: rgba(230, 237, 255, 0.6) !important;
}

.megamenu .level-1-menu .level-1-menu-card>div {
  /* height: 3rem; */
}

.megamenu .visibleMenu {
  /* display: flex !important; */
  opacity: 1;
}

.level-1-menu-wrapper {
  overflow-y: auto;
  /* max-height: 60vh; */
  position: relative;
  /* padding-bottom: 72px; */
}

.level-1-menu-wrapper::-webkit-scrollbar {
  width: 0.2em;
}

.level-1-menu-wrapper .title {
  width: 80%;
  border-bottom: 1px solid #93aad2;
  margin-bottom: 15px;
}

.level-1-menu-wrapper .title h5 {
  color: #93aad2;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.level-1-menu-wrapper .title img {
  cursor: pointer;
}

/* Navabr responsive */
.navbar-toggler {
  border: none !important;
  box-shadow: none I !important;
}

.navbar-toggler img {
  height: 30px;
  width: 30px;
}

.navbar-toggler:focus {
  border: none !important;
  box-shadow: none !important;
}

/* offcanvas for mobile */
.header_nav .offcanvas-header .mob-nav {
  padding: 0 !important;
}

.header_nav .offcanvas-body {
  padding: 0;
}

.header_nav .navbar-nav .nav-link.active {
  color: #346dfe;
}

.header_nav .navbar-nav .nav-link {
  align-items: center;
  color: #031430;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 30px 8px;
}

.header_nav .auth-btn .btn {
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 20px;
  min-width: 119px;
}

@media (min-width: 1126px) and (max-width: 1199px) {
  .navbar .container-fluid {
    padding: 0px 25px;
  }
}

@media (min-width: 1024px) and (max-width: 1126px) {
  .navbar .container-fluid {
    padding: 0px 25px;
  }

  .navbar .navbar-nav {
    gap: 14px;
  }

  .navbar .auth-btn .btn {
    font-size: 14px;
    min-width: auto;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .navbar .container-fluid {
    padding: 0px 25px;
  }

  .navbar .navbar-nav {
    gap: 14px;
  }

  .navbar .auth-btn .btn {
    font-size: 14px;
    min-width: auto;
  }
}

@media screen and (max-width: 991px) {
  .megamenu {
    display: none !important;
  }

  .navbar-nav li.nav-item a {
    padding: 5px 0px !important;
  }

  .navbar .container-fluid {
    padding: 0px !important;
  }

  .navbar-nav li.nav-item a {
    padding: 20px !important;
    background: #ffffff;
    border-top: 1px solid rgba(91, 119, 147, 0.12);
  }

  .navbar-nav li.nav-item:last-child a {
    border-bottom: 1px solid rgba(91, 119, 147, 0.12);
  }

  .mob-nav {
    padding: 15px !important;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar .navbar-nav {
    gap: 0px !important;
  }

  .navbar {
    margin: 0px;
    padding: 0px;
  }

  #navbarScroll {
    height: 100vh;
    box-shadow: none;
    background: white;
  }

  .navbar-nav {
    padding: 0px !important;
    margin: 0px !important;
  }

  .auth-btn {
    background-color: white;
    padding: 20px !important;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .auth-btn a {
    width: 100%;
    padding: 15px;
  }

  .nav-item {
    position: relative;
  }

  .canvas-body {
    margin: 0 auto 80px !important;
  }
}

@media screen and (max-width: 769px) {
  .offcanvas-header .offcanvas-title a {
    display: flex;
    align-items: center;
  }

  .offcanvas2 .offcanvas-body .level-2-mob-menu-card img {
    width: 19px;
  }

  .offcanvas2 .offcanvas-body .level-2-mob-menu-card h6 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 600px) {}